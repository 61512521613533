@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}.IndexHero {
  position: relative;
  color: #795fc5;
}
@media (min-width: 1024px) {
  .IndexHero {
    min-height: 44vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.IndexHero-wrapper {
  position: relative;
  padding: 0 0.04166667rem 0.09444444rem;
}
@media (max-width: 1023px) {
  .IndexHero-wrapper {
    padding: 0.0875rem 0.01388889rem 0.05138889rem;
  }
}
.IndexHero p {
  font-family: 'Kazimir', serif;
  font-weight: 400;
  font-size: 0.02222222rem;
  line-height: 0.02777778rem;
  letter-spacing: -0.00115972rem;
  text-align: left;
  opacity: 0;
  transition: 0.35s ease-out 0.7s;
  will-change: opacity;
}
@media (max-width: 1023px) {
  .IndexHero p {
    font-size: 0.01111111rem;
    line-height: 0.01388889rem;
    letter-spacing: -0.00057639rem;
  }
}
@media (min-width: 1024px) {
  .IndexHero p {
    font-size: 0.01805556rem;
    line-height: 0.02222222rem;
    letter-spacing: -0.00056424rem;
  }
}
@media (max-width: 1023px) {
  .IndexHero p {
    font-size: 0.01111111rem;
    line-height: 0.01388889rem;
    letter-spacing: -0.00013889rem;
  }
}
.IndexHero p.show {
  opacity: 1;
}
