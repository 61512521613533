@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}.IndexLoader {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: white;
  z-index: 12000;
}
@media (max-width: 1023px) {
  .IndexLoader {
    display: none;
  }
}
.IndexLoader.show .IndexLoader-line {
  transform: none;
  opacity: 0;
}
.IndexLoader.show .IndexLoader-left {
  transform: none;
}
.IndexLoader.show .IndexLoader-right {
  opacity: 1;
}
.IndexLoader-line {
  position: absolute;
  width: 2px;
  left: 50%;
  margin-left: -2px;
  top: 0;
  height: 100%;
  background: #795fc5;
  transform-origin: 50% 0;
  transform: scaleY(0);
  opacity: 1;
  transition: 0.35s transform ease-out, opacity 0.35s 0.35s;
  will-change: opacity;
}
.IndexLoader-left {
  position: absolute;
  width: 50%;
  left: 0;
  top: 0;
  height: 100%;
  background: #795fc5;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: 0.35s transform ease-out 0.35s;
  will-change: opacity;
}
.IndexLoader-right {
  position: absolute;
  width: 50%;
  right: 0;
  top: 0;
  height: 100%;
  background: #F7F5F2;
  opacity: 0;
  transition: 0.35s opacity ease-out 0.35s;
  will-change: opacity;
}
