@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}.IndexFeedbackItem {
  position: relative;
  border-top: 0.00138889rem solid #795fc5;
  margin-bottom: 0.02291667rem;
  padding-top: 0.00416667rem;
  opacity: 0;
  transition: 0.35s ease-out 0.1s;
  display: flex;
  justify-content: space-between;
  will-change: opacity;
}
@media (max-width: 1023px) {
  .IndexFeedbackItem {
    display: block;
    padding-top: 0.00625rem;
  }
}
.IndexFeedbackItem.show {
  opacity: 1;
}
.IndexFeedbackItem-left {
  flex: 0 0 0.19444444rem;
}
.IndexFeedbackItem-left h3 {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.02222222rem;
  line-height: 0.02777778rem;
  letter-spacing: -0.00115741rem;
}
@media (max-width: 1023px) {
  .IndexFeedbackItem-left h3 {
    font-size: 0.01666667rem;
    line-height: 0.02777778rem;
    letter-spacing: -0.00086806rem;
  }
}
.IndexFeedbackItem-left h3 a {
  position: relative;
  display: inline-block;
  color: #795fc5;
  text-decoration: none;
  cursor: pointer;
}
.IndexFeedbackItem-left h3 a:before {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00138889rem solid #795fc5;
  top: 95%;
  width: 100%;
  transform: scaleX(1);
  transform-origin: 100% 0;
  transition: 0s;
}
.IndexFeedbackItem-left h3 a:after {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00138889rem solid #795fc5;
  top: 95%;
  width: 100%;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0s;
}
.isDesktop .IndexFeedbackItem-left h3 a:hover:before,
.isMobile .IndexFeedbackItem-left h3 a:active:before {
  transform: scaleX(0);
  transition: transform 0.195s ease-out;
}
.isDesktop .IndexFeedbackItem-left h3 a:hover:after,
.isMobile .IndexFeedbackItem-left h3 a:active:after {
  transform: scaleX(1);
  transition: transform 0.195s ease-in 0.195s;
}
.IndexFeedbackItem-left h3 a:before {
  transform: scaleX(0);
}
.IndexFeedbackItem-left h3 a:after {
  transition: transform 0.15s ease-out;
}
.isDesktop .IndexFeedbackItem-left h3 a:hover:after,
.isMobile .IndexFeedbackItem-left h3 a:active:after {
  transition: transform 0.15s ease-in;
}
.IndexFeedbackItem-left p {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.00833333rem;
  line-height: 0.01388889rem;
  margin-top: 0.00902778rem;
}
@media (max-width: 1023px) {
  .IndexFeedbackItem-left p {
    font-size: 0.00833333rem;
    line-height: 0.01388889rem;
  }
}
@media (max-width: 1023px) {
  .IndexFeedbackItem-left p {
    font-size: 0.00694444rem;
    margin-top: -0.00347222rem;
  }
}
.IndexFeedbackItem-left p a {
  position: relative;
  display: inline-block;
  color: #795fc5;
  text-decoration: none;
  cursor: pointer;
}
.IndexFeedbackItem-left p a:before {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00069444rem solid #795fc5;
  top: 85%;
  width: 100%;
  transform: scaleX(1);
  transform-origin: 100% 0;
  transition: 0s;
}
.IndexFeedbackItem-left p a:after {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00069444rem solid #795fc5;
  top: 85%;
  width: 100%;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0s;
}
.isDesktop .IndexFeedbackItem-left p a:hover:before,
.isMobile .IndexFeedbackItem-left p a:active:before {
  transform: scaleX(0);
  transition: transform 0.195s ease-out;
}
.isDesktop .IndexFeedbackItem-left p a:hover:after,
.isMobile .IndexFeedbackItem-left p a:active:after {
  transform: scaleX(1);
  transition: transform 0.195s ease-in 0.195s;
}
.IndexFeedbackItem-right {
  flex: 0 0 0.19444444rem;
  padding-top: 0.01319444rem;
}
@media (max-width: 1023px) {
  .IndexFeedbackItem-right {
    padding-top: 0.01458333rem;
  }
}
.IndexFeedbackItem-message-body {
  font-family: 'Kazimir', serif;
  font-weight: 400;
  font-size: 0.00833333rem;
  line-height: 0.01388889rem;
  overflow: hidden;
  position: relative;
  transition: 0.4s ease max-height;
}
@media (max-width: 1023px) {
  .IndexFeedbackItem-message-body {
    font-size: 0.00833333rem;
    line-height: 0.01388889rem;
  }
}
.IndexFeedbackItem-message-body.collapsed p:nth-child(1n+2) {
  display: none;
}
.IndexFeedbackItem-message-body p {
  margin-bottom: 0.00694444rem;
}
.IndexFeedbackItem-message-body p:last-child {
  margin-bottom: 0;
}
.IndexFeedbackItem-expand-wrapper {
  transition: 0.4s opacity, 0.4s height;
  height: 0.01805556rem;
}
@media (max-width: 1023px) {
  .IndexFeedbackItem-expand-wrapper {
    height: 0.01388889rem;
  }
}
.IndexFeedbackItem-expand-wrapper.hidden {
  pointer-events: none;
  opacity: 0;
  height: 0;
}
.IndexFeedbackItem-expand {
  font-family: 'Kazimir', serif;
  font-weight: 400;
  font-size: 0.00833333rem;
  line-height: 0.01388889rem;
  position: relative;
  display: inline-block;
  color: #795fc5;
  text-decoration: none;
  cursor: pointer;
}
@media (max-width: 1023px) {
  .IndexFeedbackItem-expand {
    font-size: 0.00833333rem;
    line-height: 0.01388889rem;
  }
}
.IndexFeedbackItem-expand:before {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00069444rem solid #795fc5;
  top: 80%;
  width: 100%;
  transform: scaleX(1);
  transform-origin: 100% 0;
  transition: 0s;
}
.IndexFeedbackItem-expand:after {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00069444rem solid #795fc5;
  top: 80%;
  width: 100%;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0s;
}
.isDesktop .IndexFeedbackItem-expand:hover:before,
.isMobile .IndexFeedbackItem-expand:active:before {
  transform: scaleX(0);
  transition: transform 0.195s ease-out;
}
.isDesktop .IndexFeedbackItem-expand:hover:after,
.isMobile .IndexFeedbackItem-expand:active:after {
  transform: scaleX(1);
  transition: transform 0.195s ease-in 0.195s;
}
.IndexFeedbackItem-expand:before {
  border-bottom: 0.00069444rem dotted #795fc5;
}
.IndexFeedbackItem-expand:after {
  border-bottom: 0.00069444rem dotted #795fc5;
}
