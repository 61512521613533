@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}.IndexRoute {
  position: relative;
  color: #795fc5;
  border-bottom: 0.00138889rem solid #795fc5;
  overflow: hidden;
}
.IndexRoute-wrapper {
  position: relative;
  padding: 0.0375rem 0.04166667rem 0.05555556rem;
}
@media (max-width: 1023px) {
  .IndexRoute-wrapper {
    padding: 0.01736111rem 0.01388889rem 0.04166667rem;
  }
}
.IndexRoute-map {
  position: absolute;
  left: 0;
  top: -0.00208333rem;
  width: 200%;
}
@media (max-width: 1023px) {
  .IndexRoute-map {
    width: 425%;
    top: -0.08958333rem;
  }
}
.IndexRoute-map-aspect {
  width: 100%;
  height: 0;
  padding-top: 61.11111111%;
}
.IndexRoute-map img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: -0.00069444rem;
  margin-top: 0.00069444rem;
}
.IndexRoute-map svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.IndexRoute-map svg path#route {
  stroke: #45413D;
  stroke-dasharray: 2;
}
.IndexRoute-map svg path#route_mask_1,
.IndexRoute-map svg path#route_mask_2 {
  stroke: #F7F5F2;
  stroke-width: 3;
  shape-rendering: optimizeSpeed;
}
.IndexRoute-map svg g {
  position: relative;
}
.IndexRoute-map svg circle {
  fill: #795fc5;
  stroke: white;
  opacity: 0;
}
.IndexRoute-map svg text {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 500;
  fill: #45413D;
  opacity: 0;
}
.IndexRoute h2 {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.08333333rem;
  line-height: 0.08333333rem;
  letter-spacing: -0.00607639rem;
  text-transform: uppercase;
  position: relative;
  margin-left: -0.00763889rem;
}
@media (max-width: 1023px) {
  .IndexRoute h2 {
    font-size: 0.04166667rem;
    line-height: 0.04166667rem;
    letter-spacing: -0.00304167rem;
  }
}
@media (max-width: 1023px) {
  .IndexRoute h2 {
    margin-left: -0.00347222rem;
  }
}
.IndexRoute-numbers {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 0.32291667rem;
}
@media (max-width: 1023px) {
  .IndexRoute-numbers {
    margin-top: 0.27291667rem;
  }
}
.IndexRoute-number {
  position: relative;
  flex: 0 0 0.19444444rem;
  border-top: 0.00138889rem solid #795fc5;
  height: 0.03333333rem;
  margin-bottom: 0.02222222rem;
}
@media (max-width: 1023px) {
  .IndexRoute-number {
    flex: 0 0 0.09027778rem;
    height: 0.04930556rem;
    margin-bottom: 0.00625rem;
  }
}
.IndexRoute-number.fade .IndexRoute-number-value {
  opacity: 0;
  transform: translateX(0.01388889rem);
  transition: 0.35s ease-out;
  will-change: opacity;
}
.IndexRoute-number.fade.show .IndexRoute-number-value {
  opacity: 1;
  transform: none;
}
.IndexRoute-number-label {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.00833333rem;
  line-height: 0.01388889rem;
  font-weight: 500;
}
@media (max-width: 1023px) {
  .IndexRoute-number-label {
    font-size: 0.00833333rem;
    line-height: 0.01388889rem;
  }
}
@media (min-width: 1024px) {
  .IndexRoute-number-label {
    position: absolute;
    left: 0;
    top: 0.01319444rem;
  }
}
@media (max-width: 1023px) {
  .IndexRoute-number-label {
    font-size: 0.00694444rem;
    margin-top: 0.00763889rem;
  }
}
.IndexRoute-number-value {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.02222222rem;
  line-height: 0.02777778rem;
  letter-spacing: -0.00115741rem;
}
@media (max-width: 1023px) {
  .IndexRoute-number-value {
    font-size: 0.01666667rem;
    line-height: 0.02777778rem;
    letter-spacing: -0.00086806rem;
  }
}
@media (min-width: 1024px) {
  .IndexRoute-number-value {
    position: absolute;
    right: 0;
    top: 0.00416667rem;
  }
}
@media (max-width: 1023px) {
  .IndexRoute-number-value {
    margin-top: -0.00138889rem;
  }
}
