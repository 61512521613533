@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}.Index {
  display: block;
}
.Index-content {
  position: relative;
  width: 100%;
}
.Index-contentWrapper {
  position: relative;
}
@media (min-width: 1024px) {
  .Index-contentWrapper.loading {
    height: 100vh;
    overflow: hidden;
  }
}
.Index-rightColumn {
  background: #F7F5F2;
  padding-left: 50%;
}
@media (max-width: 1023px) {
  .Index-rightColumn {
    padding-left: 0;
    margin-top: 0 !important;
  }
}
.Index-order-button {
  position: fixed;
  z-index: 8000;
  opacity: 0;
  transition: 0.35s ease-out 2.1s;
  will-change: opacity;
  pointer-events: none;
}
@media (max-width: 1023px) and (orientation: landscape) {
  .Index-order-button {
    display: none;
  }
}
.Index-order-button.show {
  opacity: 1;
  pointer-events: all;
}
.Index-order-button.shown {
  transition: 0.35s ease-out opacity;
}
@media (min-width: 1024px) {
  .Index-order-button {
    top: 0.01388889rem;
    right: 0.01388889rem;
  }
}
@media (max-width: 1023px) {
  .Index-order-button {
    bottom: 0.01388889rem;
    left: 0.02777778rem;
  }
}
.Index-order-button a {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 500;
  font-size: 0.00833333rem;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding: 0 0.02361111rem 0 0.02361111rem;
  height: 0.02777778rem;
  line-height: 0.02638889rem;
  text-decoration: none;
  box-sizing: content-box;
  vertical-align: middle;
  border-radius: 0.01388889rem;
  box-sizing: border-box;
  color: #F7F5F2;
  border: 0.00138889rem solid rgba(121, 95, 197, 0);
  background: #795fc5;
}
.Index-order-button a[disabled] {
  pointer-events: none;
}
.isDesktop .Index-order-button a {
  transition: 0.15s ease color, 0.15s ease border, 0.15s ease background;
}
.Index-order-button a.loading {
  pointer-events: none;
  color: transparent;
}
.Index-order-button a.loading span {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -0.00694444rem;
  margin-top: -0.00694444rem;
}
.isDesktop .Index-order-button a:hover,
.isMobile .Index-order-button a:active,
.Index-order-button a.active {
  color: #795fc5;
  border: 0.00138889rem solid #795fc5;
  background: #f7f5f2;
}
.Index-order-button a.loading span {
  -webkit-animation: loading-animation-spin 700ms infinite linear;
          animation: loading-animation-spin 700ms infinite linear;
  width: 0.01388889rem;
  height: 0.01388889rem;
  border-radius: 50%;
  border: 0.00173611rem solid #000;
  border-right-color: transparent;
}
@media (max-width: 1023px) {
}
@-webkit-keyframes loading-animation-spin {
  to {
    transform: rotate(1turn);
  }
}
@keyframes loading-animation-spin {
  to {
    transform: rotate(1turn);
  }
}
@media (max-width: 1023px) {
  .Index-order-button a {
    width: 0.16666667rem;
  }
}
