@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}.IndexFooter {
  position: relative;
  color: white;
  background: #795fc5;
  height: 100vh;
  min-height: 40vw;
}
@media (max-width: 1023px) {
  .IndexFooter {
    min-height: 70vw;
  }
}
.IndexFooter-center {
  position: absolute;
  top: 0.03611111rem;
  bottom: 0.03055556rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 0.03541667rem;
}
@media (max-width: 1023px) {
  .IndexFooter-center {
    padding-left: 0.01388889rem;
    top: 0rem;
    bottom: 0.02361111rem;
  }
}
.IndexFooter-center a {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-size: 0.08333333rem;
  line-height: 0.06944444rem;
  letter-spacing: -0.00607639rem;
  text-transform: uppercase;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  color: white;
  z-index: 1;
  width: 0.3125rem;
}
@media (max-width: 1023px) {
  .IndexFooter-center a {
    width: 100%;
    font-size: 0.04166667rem;
    line-height: 0.03472222rem;
    letter-spacing: -0.00304167rem;
  }
}
.isDesktop .IndexFooter-center a:hover svg,
.isMobile .IndexFooter-center a:active svg {
  left: 0;
}
.IndexFooter-center a span {
  position: relative;
  display: inline-block;
  vertical-align: 0.01597222rem;
  width: 0.03888889rem;
  height: 0.02291667rem;
  margin-left: 0.00208333rem;
  overflow: hidden;
}
@media (max-width: 1023px) {
  .IndexFooter-center a span {
    vertical-align: 0.00208333rem;
    margin-left: 0.00486111rem;
  }
}
.IndexFooter-center a svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  left: -0.00902778rem;
  fill: #fff;
}
@media (max-width: 1023px) {
}
.isDesktop .IndexFooter-center a svg {
  transition: 0.15s left ease-out;
}
.IndexFooter-center p {
  font-family: 'Kazimir', serif;
  font-weight: 400;
  font-size: 0.01111111rem;
  line-height: 0.01388889rem;
  display: flex;
  align-items: center;
  margin-top: 0.01458333rem;
  margin-left: 0.00625rem;
}
@media (max-width: 1023px) {
  .IndexFooter-center p {
    font-size: 0.01111111rem;
    line-height: 0.01388889rem;
  }
}
@media (max-width: 1023px) {
  .IndexFooter-center p {
    font-size: 0.00763889rem;
    margin-left: 0.00208333rem;
    line-height: 0.01388889rem;
  }
}
.IndexFooter-center p a {
  position: relative;
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-family: 'Kazimir', serif;
  font-weight: 400;
  font-size: 0.01111111rem;
  line-height: 0.01805556rem;
  letter-spacing: 0.00006944rem;
  text-transform: none;
  width: auto;
  display: inline-block;
  margin-left: 0.00486111rem;
}
.IndexFooter-center p a:before {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00069444rem solid white;
  top: 85%;
  width: 100%;
  transform: scaleX(1);
  transform-origin: 100% 0;
  transition: 0s;
}
.IndexFooter-center p a:after {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00069444rem solid white;
  top: 85%;
  width: 100%;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0s;
}
.isDesktop .IndexFooter-center p a:hover:before,
.isMobile .IndexFooter-center p a:active:before {
  transform: scaleX(0);
  transition: transform 0.195s ease-out;
}
.isDesktop .IndexFooter-center p a:hover:after,
.isMobile .IndexFooter-center p a:active:after {
  transform: scaleX(1);
  transition: transform 0.195s ease-in 0.195s;
}
@media (max-width: 1023px) {
  .IndexFooter-center p a {
    margin-left: 0.00347222rem;
    font-size: 0.00763889rem;
    line-height: 0.01388889rem;
  }
}
.IndexFooter-wrapper {
  position: relative;
  padding: 0 0.04166667rem 0.025rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media (max-width: 1023px) {
  .IndexFooter-wrapper {
    padding: 0 0.01388889rem 0.01319444rem;
  }
}
.IndexFooter-copyright p {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-size: 0.01111111rem;
  line-height: 0.01388889rem;
  text-transform: uppercase;
  font-weight: 500;
}
@media (max-width: 1023px) {
  .IndexFooter-copyright p {
    font-size: 0.00694444rem;
    line-height: 0.00902778rem;
    letter-spacing: -0.00009028rem;
    margin-bottom: 0rem;
  }
}
.IndexFooter-copyright a {
  position: relative;
  display: inline-block;
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.IndexFooter-copyright a:before {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00069444rem solid rgba(247, 245, 242, 0.3);
  top: 95%;
  width: 100%;
  transform: scaleX(1);
  transform-origin: 100% 0;
  transition: 0s;
}
.IndexFooter-copyright a:after {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00069444rem solid rgba(247, 245, 242, 0.3);
  top: 95%;
  width: 100%;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0s;
}
.isDesktop .IndexFooter-copyright a:hover:before,
.isMobile .IndexFooter-copyright a:active:before {
  transform: scaleX(0);
  transition: transform 0.195s ease-out;
}
.isDesktop .IndexFooter-copyright a:hover:after,
.isMobile .IndexFooter-copyright a:active:after {
  transform: scaleX(1);
  transition: transform 0.195s ease-in 0.195s;
}
