@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}.IndexBook {
  position: relative;
  color: #795fc5;
  border-bottom: 0.00138889rem solid #795fc5;
  overflow: hidden;
}
.IndexBook-wrapper {
  position: relative;
  padding-top: 0.0375rem;
  padding: 0.0375rem 0.04166667rem 0.05555556rem;
}
@media (max-width: 1023px) {
  .IndexBook-wrapper {
    padding: 0.01736111rem 0.01388889rem 0.04166667rem;
  }
}
.IndexBook h2 {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.08333333rem;
  line-height: 0.08333333rem;
  letter-spacing: -0.00607639rem;
  text-transform: uppercase;
  position: relative;
  margin-left: -0.00694444rem;
}
@media (max-width: 1023px) {
  .IndexBook h2 {
    font-size: 0.04166667rem;
    line-height: 0.04166667rem;
    letter-spacing: -0.00304167rem;
  }
}
@media (max-width: 1023px) {
  .IndexBook h2 {
    margin-left: 0rem;
  }
}
.IndexBook-image {
  position: relative;
  display: block;
  margin: 0 auto 0.01388889rem;
  width: 0.34513889rem;
  height: 0.29166667rem;
}
@media (max-width: 1023px) {
  .IndexBook-image {
    margin: 0 auto 0.00902778rem;
    width: 0.16736111rem;
    height: 0.14097222rem;
  }
}
.IndexBook-image canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: 0.00347222rem;
}
@media (max-width: 1023px) {
  .IndexBook-image canvas {
    margin-left: 0rem;
  }
}
.IndexBook-features {
  margin-top: 0.01666667rem;
}
@media (max-width: 1023px) {
  .IndexBook-features {
    margin-top: 0.01388889rem;
  }
}
.IndexBook-feature {
  position: relative;
  border-top: 0.00138889rem solid #795fc5;
  margin-bottom: 0.02777778rem;
  padding-top: 0.00416667rem;
  min-height: 0.13888889rem;
  opacity: 0;
  transition: 0.35s ease-out 0.1s;
  will-change: opacity;
}
@media (max-width: 1023px) {
  .IndexBook-feature {
    padding-top: 0.00625rem;
    min-height: 0;
  }
}
.IndexBook-feature.show {
  opacity: 1;
}
.IndexBook-feature-title {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.02222222rem;
  line-height: 0.02777778rem;
  letter-spacing: -0.00115741rem;
  width: 0.19444444rem;
}
@media (max-width: 1023px) {
  .IndexBook-feature-title {
    font-size: 0.01666667rem;
    line-height: 0.02777778rem;
    letter-spacing: -0.00086806rem;
  }
}
.IndexBook-feature-text {
  font-family: 'Kazimir', serif;
  font-weight: 400;
  font-size: 0.00833333rem;
  line-height: 0.01388889rem;
  width: 0.19444444rem;
  margin-top: 0.01319444rem;
}
@media (max-width: 1023px) {
  .IndexBook-feature-text {
    font-size: 0.00833333rem;
    line-height: 0.01388889rem;
  }
}
@media (max-width: 1023px) {
  .IndexBook-feature-text {
    margin-top: 0.01111111rem;
  }
}
@media (min-width: 1024px) {
  .IndexBook-feature img {
    position: absolute;
    right: 0;
    top: 0.0125rem;
    width: 0.19444444rem;
    height: 0.125rem;
  }
}
@media (max-width: 1023px) {
  .IndexBook-feature img {
    width: 0.19444444rem;
    height: 0.125rem;
    margin-top: 0.00902778rem;
  }
}
.IndexBook-numbers {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.IndexBook-number {
  position: relative;
  flex: 0 0 0.19444444rem;
  border-top: 0.00138889rem solid #795fc5;
  height: 0.03333333rem;
  margin-bottom: 0.02222222rem;
}
@media (max-width: 1023px) {
  .IndexBook-number {
    flex: 0 0 0.09027778rem;
    height: 0.04930556rem;
    margin-bottom: 0.00625rem;
  }
}
.IndexBook-number.wide {
  flex: 0 0 0.41666667rem;
}
@media (max-width: 1023px) {
  .IndexBook-number.wide {
    height: 0.03541667rem;
    flex: 0 0 0.19444444rem;
  }
}
@media (max-width: 1023px) {
  .IndexBook-number.wide .IndexBook-number-label {
    margin-top: 0.0125rem;
  }
}
.IndexBook-number.wide .IndexBook-number-value {
  letter-spacing: 0;
  opacity: 0;
  transform: translateX(0.01388889rem);
  transition: 0.35s ease-out 0.1s;
  will-change: opacity;
}
@media (max-width: 1023px) {
  .IndexBook-number.wide .IndexBook-number-value {
    letter-spacing: -0.00069444rem;
    position: absolute;
    right: 0;
    top: 0.00763889rem;
  }
}
.IndexBook-number.wide.show .IndexBook-number-value {
  opacity: 1;
  transform: none;
}
.IndexBook-number-label {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.00833333rem;
  line-height: 0.01388889rem;
  font-weight: 500;
}
@media (max-width: 1023px) {
  .IndexBook-number-label {
    font-size: 0.00833333rem;
    line-height: 0.01388889rem;
  }
}
@media (min-width: 1024px) {
  .IndexBook-number-label {
    position: absolute;
    left: 0;
    top: 0.01319444rem;
  }
}
@media (max-width: 1023px) {
  .IndexBook-number-label {
    font-size: 0.00694444rem;
    margin-top: 0.00763889rem;
  }
}
.IndexBook-number-value {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.02222222rem;
  line-height: 0.02777778rem;
  letter-spacing: -0.00115741rem;
}
@media (max-width: 1023px) {
  .IndexBook-number-value {
    font-size: 0.01666667rem;
    line-height: 0.02777778rem;
    letter-spacing: -0.00086806rem;
  }
}
@media (min-width: 1024px) {
  .IndexBook-number-value {
    position: absolute;
    right: 0;
    top: 0.00416667rem;
  }
}
@media (max-width: 1023px) {
  .IndexBook-number-value {
    margin-top: -0.00138889rem;
  }
}
