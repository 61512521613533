@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}.SuccessPopup {
  position: relative;
  color: white;
  height: 100vh;
  min-height: 41vw;
  display: none;
}
@media (max-width: 1023px) {
  .SuccessPopup {
    min-height: 100vw;
  }
}
.SuccessPopup-wrapper {
  position: relative;
  height: 100%;
  width: 0.86111111rem;
  padding: 0.04513889rem 0 0.05rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1023px) {
  .SuccessPopup-wrapper {
    width: 0.19444444rem;
    padding: 0.01527778rem 0 0.025rem;
  }
}
.SuccessPopup h1 {
  font-weight: 400;
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-size: 0.08333333rem;
  line-height: 0.06944444rem;
  letter-spacing: -0.00607639rem;
  text-transform: uppercase;
  width: 0.48611111rem;
}
@media (max-width: 1023px) {
  .SuccessPopup h1 {
    width: 100%;
    font-size: 0.03333333rem;
    line-height: 0.02777778rem;
    letter-spacing: -0.003125rem;
  }
}
.SuccessPopup-text {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.02222222rem;
  line-height: 0.02777778rem;
  letter-spacing: -0.00115741rem;
  width: 0.52777778rem;
}
@media (max-width: 1023px) {
  .SuccessPopup-text {
    font-size: 0.01666667rem;
    line-height: 0.02777778rem;
    letter-spacing: -0.00086806rem;
  }
}
@media (max-width: 1023px) {
  .SuccessPopup-text {
    width: 100%;
    font-size: 0.01111111rem;
    line-height: 0.01388889rem;
    letter-spacing: -0.00057639rem;
    margin-top: 0.04166667rem;
  }
}
