@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}.IndexCredits {
  position: relative;
  color: #795fc5;
  border-bottom: 0.00138889rem solid #795fc5;
  overflow: hidden;
}
.IndexCredits-wrapper {
  position: relative;
  padding-top: 0.0375rem;
  padding: 0.0375rem 0.04166667rem 0.04166667rem;
}
@media (max-width: 1023px) {
  .IndexCredits-wrapper {
    padding: 0.01736111rem 0.01388889rem 0.04166667rem;
  }
}
.IndexCredits h2 {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.08333333rem;
  line-height: 0.08333333rem;
  letter-spacing: -0.00607639rem;
  text-transform: uppercase;
  position: relative;
}
@media (max-width: 1023px) {
  .IndexCredits h2 {
    font-size: 0.04166667rem;
    line-height: 0.04166667rem;
    letter-spacing: -0.00304167rem;
  }
}
.IndexCredits-text {
  font-family: 'Kazimir', serif;
  font-weight: 400;
  font-size: 0.01111111rem;
  line-height: 0.01388889rem;
  margin-top: 0.04930556rem;
  opacity: 0;
  transition: 0.35s ease-out 0.1s;
  will-change: opacity;
}
@media (max-width: 1023px) {
  .IndexCredits-text {
    font-size: 0.01111111rem;
    line-height: 0.01388889rem;
  }
}
@media (max-width: 1023px) {
  .IndexCredits-text {
    margin-top: 0.02708333rem;
  }
}
.IndexCredits-text.show {
  opacity: 1;
}
.IndexCredits-items {
  margin-top: 0.05138889rem;
}
@media (max-width: 1023px) {
  .IndexCredits-items {
    margin-top: 0.02361111rem;
  }
}
.IndexCredits-item {
  position: relative;
  width: 100%;
  border-top: 0.00138889rem solid #795fc5;
  margin-bottom: 0.02222222rem;
  opacity: 0;
  transition: 0.35s ease-out 0.1s;
  will-change: opacity;
}
@media (max-width: 1023px) {
  .IndexCredits-item {
    margin-bottom: 0.00625rem;
  }
}
.IndexCredits-item.show {
  opacity: 1;
}
.IndexCredits-item-label {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.00833333rem;
  line-height: 0.01388889rem;
  font-weight: 500;
}
@media (max-width: 1023px) {
  .IndexCredits-item-label {
    font-size: 0.00833333rem;
    line-height: 0.01388889rem;
  }
}
@media (min-width: 1024px) {
  .IndexCredits-item-label {
    position: absolute;
    left: 0;
    top: 0.01319444rem;
  }
}
.IndexCredits-item-label a {
  position: relative;
  display: inline-block;
  color: #795fc5;
  text-decoration: none;
  cursor: pointer;
}
.IndexCredits-item-label a:before {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00069444rem solid #795fc5;
  top: 85%;
  width: 100%;
  transform: scaleX(1);
  transform-origin: 100% 0;
  transition: 0s;
}
.IndexCredits-item-label a:after {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00069444rem solid #795fc5;
  top: 85%;
  width: 100%;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0s;
}
.isDesktop .IndexCredits-item-label a:hover:before,
.isMobile .IndexCredits-item-label a:active:before {
  transform: scaleX(0);
  transition: transform 0.195s ease-out;
}
.isDesktop .IndexCredits-item-label a:hover:after,
.isMobile .IndexCredits-item-label a:active:after {
  transform: scaleX(1);
  transition: transform 0.195s ease-in 0.195s;
}
@media (max-width: 1023px) {
  .IndexCredits-item-label {
    font-size: 0.00694444rem;
    margin-top: 0.00763889rem;
  }
}
.IndexCredits-item-names {
  width: 100%;
  text-align: right;
  padding-top: 0.00416667rem;
}
@media (max-width: 1023px) {
  .IndexCredits-item-names {
    text-align: left;
    padding-top: 0;
    margin-top: -0.00138889rem;
  }
}
.IndexCredits-item-names a {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.02222222rem;
  line-height: 0.02777778rem;
  letter-spacing: -0.00115741rem;
  position: relative;
  display: inline-block;
  color: #795fc5;
  text-decoration: none;
  cursor: pointer;
  letter-spacing: -0.00138889rem;
}
@media (max-width: 1023px) {
  .IndexCredits-item-names a {
    font-size: 0.01666667rem;
    line-height: 0.02777778rem;
    letter-spacing: -0.00086806rem;
  }
}
.IndexCredits-item-names a:before {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00138889rem solid #795fc5;
  top: 95%;
  width: 100%;
  transform: scaleX(1);
  transform-origin: 100% 0;
  transition: 0s;
}
.IndexCredits-item-names a:after {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00138889rem solid #795fc5;
  top: 95%;
  width: 100%;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0s;
}
.isDesktop .IndexCredits-item-names a:hover:before,
.isMobile .IndexCredits-item-names a:active:before {
  transform: scaleX(0);
  transition: transform 0.195s ease-out;
}
.isDesktop .IndexCredits-item-names a:hover:after,
.isMobile .IndexCredits-item-names a:active:after {
  transform: scaleX(1);
  transition: transform 0.195s ease-in 0.195s;
}
.IndexCredits-item-names a:before {
  transform: scaleX(0);
}
.IndexCredits-item-names a:after {
  transition: transform 0.15s ease-out;
}
.isDesktop .IndexCredits-item-names a:hover:after,
.isMobile .IndexCredits-item-names a:active:after {
  transition: transform 0.15s ease-in;
}
.IndexCredits-item-names span {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.02222222rem;
  line-height: 0.02777778rem;
  letter-spacing: -0.00115741rem;
  color: #795fc5;
  letter-spacing: -0.00138889rem;
}
@media (max-width: 1023px) {
  .IndexCredits-item-names span {
    font-size: 0.01666667rem;
    line-height: 0.02777778rem;
    letter-spacing: -0.00086806rem;
  }
}
