@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}.CommonGalleryMobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.CommonGalleryMobile-wrapper {
  position: absolute;
  bottom: 0.05486111rem;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.CommonGalleryMobile-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.35s ease-out opacity;
}
.CommonGalleryMobile-image-wrapper {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.CommonGalleryMobile-image-wrapper::-webkit-scrollbar {
  display: none;
}
.CommonGalleryMobile-image-wrapper img {
  width: 100%;
}
.CommonGalleryMobile-thumbs {
  position: absolute;
  height: 0.05555556rem;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #795fc5;
  z-index: 2;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.CommonGalleryMobile-thumbs::-webkit-scrollbar {
  display: none;
}
.CommonGalleryMobile-thumbs-wrapper {
  white-space: nowrap;
  padding-top: 0.01736111rem;
}
.CommonGalleryMobile-thumb {
  display: inline-block;
  margin: 0 0.01388889rem;
  position: relative;
  width: 0.02777778rem;
  height: 0.01944444rem;
  cursor: pointer;
}
.CommonGalleryMobile-thumb img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.CommonGalleryMobile-thumb.active {
  pointer-events: none;
}
.CommonGalleryMobile-thumb.active:after {
  content: '';
  position: absolute;
  left: -0.00694444rem;
  top: -0.00416667rem;
  bottom: -0.00416667rem;
  right: -0.00694444rem;
  border: 1px solid #fff;
}
