@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}.IndexHelp {
  position: relative;
  color: #795fc5;
  border-bottom: 0.00138889rem solid #795fc5;
  overflow: hidden;
}
.IndexHelp-wrapper {
  position: relative;
  padding-top: 0.0375rem;
  padding: 0.0375rem 0.04166667rem 0.04166667rem;
}
@media (max-width: 1023px) {
  .IndexHelp-wrapper {
    padding: 0.01736111rem 0.01388889rem 0.02777778rem;
  }
}
.IndexHelp h2 {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.08333333rem;
  line-height: 0.08333333rem;
  letter-spacing: -0.00607639rem;
  text-transform: uppercase;
  position: relative;
  margin-left: -0.00555556rem;
}
@media (max-width: 1023px) {
  .IndexHelp h2 {
    font-size: 0.04166667rem;
    line-height: 0.04166667rem;
    letter-spacing: -0.00304167rem;
  }
}
@media (max-width: 1023px) {
  .IndexHelp h2 {
    margin-left: 0;
  }
}
.IndexHelp-text {
  font-family: 'Kazimir', serif;
  font-weight: 400;
  font-size: 0.01111111rem;
  line-height: 0.01388889rem;
  margin-top: 0.04930556rem;
  opacity: 0;
  transition: 0.35s ease-out 0.1s;
  will-change: opacity;
}
@media (max-width: 1023px) {
  .IndexHelp-text {
    font-size: 0.01111111rem;
    line-height: 0.01388889rem;
  }
}
@media (max-width: 1023px) {
  .IndexHelp-text {
    margin-top: 0.02708333rem;
  }
}
.IndexHelp-text.show {
  opacity: 1;
}
.IndexHelp-progress {
  position: relative;
  margin-top: 0.02361111rem;
  height: 0.04722222rem;
  z-index: 1;
}
.IndexHelp-progress-bar {
  position: relative;
  width: 100%;
  height: 0.01388889rem;
  background: #EAE9E7;
}
.IndexHelp-progress-bar span {
  display: block;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #795fc5;
  transition: 1050ms width cubic-bezier(0.165, 0.84, 0.44, 1) 0.1s;
}
.IndexHelp-progress-total {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.00833333rem;
  line-height: 0.01388889rem;
  position: absolute;
  top: 0.02847222rem;
  left: 0;
  white-space: nowrap;
}
@media (max-width: 1023px) {
  .IndexHelp-progress-total {
    font-size: 0.00833333rem;
    line-height: 0.01388889rem;
  }
}
@media (max-width: 1023px) {
  .IndexHelp-progress-total {
    font-size: 0.00694444rem;
  }
}
.IndexHelp-progress-raised {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.02222222rem;
  line-height: 0.02777778rem;
  letter-spacing: -0.00115741rem;
  position: absolute;
  top: 0.01944444rem;
  right: 0;
  text-align: right;
  white-space: nowrap;
}
@media (max-width: 1023px) {
  .IndexHelp-progress-raised {
    font-size: 0.01666667rem;
    line-height: 0.02777778rem;
    letter-spacing: -0.00086806rem;
  }
}
@media (max-width: 1023px) {
  .IndexHelp-progress-raised {
    top: 0.02152778rem;
  }
}
.IndexHelp-funds {
  margin-top: 0.03611111rem;
}
@media (max-width: 1023px) {
  .IndexHelp-funds {
    margin-top: 0.02222222rem;
  }
}
.IndexHelp-fund {
  position: relative;
  border-top: 0.00138889rem solid #795fc5;
  margin-bottom: 0.02291667rem;
  padding-top: 0.00416667rem;
  opacity: 0;
  transition: 0.35s ease-out 0.1s;
  display: flex;
  justify-content: space-between;
  will-change: opacity;
}
@media (max-width: 1023px) {
  .IndexHelp-fund {
    display: block;
    padding-top: 0.01041667rem;
  }
}
.IndexHelp-fund.show {
  opacity: 1;
}
.IndexHelp-fund-title {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.02222222rem;
  line-height: 0.02777778rem;
  letter-spacing: -0.00115741rem;
  flex: 0 0 0.20138889rem;
}
@media (max-width: 1023px) {
  .IndexHelp-fund-title {
    font-size: 0.01666667rem;
    line-height: 0.02777778rem;
    letter-spacing: -0.00086806rem;
  }
}
@media (max-width: 1023px) {
  .IndexHelp-fund-title {
    line-height: 0.02083333rem;
  }
}
.IndexHelp-fund-text {
  font-family: 'Kazimir', serif;
  font-weight: 400;
  font-size: 0.00833333rem;
  line-height: 0.01388889rem;
  flex: 0 0 0.19444444rem;
  margin-top: 0.01319444rem;
}
@media (max-width: 1023px) {
  .IndexHelp-fund-text {
    font-size: 0.00833333rem;
    line-height: 0.01388889rem;
  }
}
@media (max-width: 1023px) {
  .IndexHelp-fund-text {
    margin-top: 0.02222222rem;
  }
}
.IndexHelp-fund-text a {
  position: relative;
  display: inline-block;
  color: #795fc5;
  text-decoration: none;
  cursor: pointer;
}
.IndexHelp-fund-text a:before {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00069444rem solid #795fc5;
  top: 80%;
  width: 100%;
  transform: scaleX(1);
  transform-origin: 100% 0;
  transition: 0s;
}
.IndexHelp-fund-text a:after {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00069444rem solid #795fc5;
  top: 80%;
  width: 100%;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0s;
}
.isDesktop .IndexHelp-fund-text a:hover:before,
.isMobile .IndexHelp-fund-text a:active:before {
  transform: scaleX(0);
  transition: transform 0.195s ease-out;
}
.isDesktop .IndexHelp-fund-text a:hover:after,
.isMobile .IndexHelp-fund-text a:active:after {
  transform: scaleX(1);
  transition: transform 0.195s ease-in 0.195s;
}
.IndexHelp-result {
  font-family: 'Kazimir', serif;
  font-weight: 400;
  font-size: 0.01111111rem;
  line-height: 0.01388889rem;
  margin-top: 0.04930556rem;
  opacity: 0;
  transition: 0.35s ease-out 0.1s;
  will-change: opacity;
}
@media (max-width: 1023px) {
  .IndexHelp-result {
    font-size: 0.01111111rem;
    line-height: 0.01388889rem;
  }
}
.IndexHelp-result a {
  position: relative;
  display: inline-block;
  color: #795fc5;
  text-decoration: none;
  cursor: pointer;
}
.IndexHelp-result a:before {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00069444rem solid #795fc5;
  top: 80%;
  width: 100%;
  transform: scaleX(1);
  transform-origin: 100% 0;
  transition: 0s;
}
.IndexHelp-result a:after {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00069444rem solid #795fc5;
  top: 80%;
  width: 100%;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0s;
}
.isDesktop .IndexHelp-result a:hover:before,
.isMobile .IndexHelp-result a:active:before {
  transform: scaleX(0);
  transition: transform 0.195s ease-out;
}
.isDesktop .IndexHelp-result a:hover:after,
.isMobile .IndexHelp-result a:active:after {
  transform: scaleX(1);
  transition: transform 0.195s ease-in 0.195s;
}
@media (max-width: 1023px) {
  .IndexHelp-result {
    margin-top: 0.02708333rem;
  }
}
.IndexHelp-result.show {
  opacity: 1;
}
