@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}.PhotosPopup-images {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.PhotosPopup-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.35s ease-out opacity;
}
@media (max-width: 1023px) {
  .PhotosPopup-image {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 1023px) {
  .PhotosPopup-image-wrapper {
    position: relative;
    display: block;
    flex: 1 0;
    width: 100%;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .PhotosPopup-image-wrapper::-webkit-scrollbar {
    display: none;
  }
}
@media (min-width: 1024px) {
  .PhotosPopup-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    object-position: 50% 100%;
  }
}
@media (min-width: 1024px) {
  .PhotosPopup-image-info {
    position: absolute;
    left: 0.01388889rem;
    bottom: 0.01388889rem;
    width: 0.22222222rem;
  }
}
@media (max-width: 1023px) {
  .PhotosPopup-image-info {
    position: relative;
    width: 100%;
    flex: 0 0;
  }
}
@media (max-width: 1023px) and (orientation: landscape) {
  .PhotosPopup-image-info {
    display: none;
  }
}
.PhotosPopup-image-info-top {
  background: #795fc5;
  padding: 0.01458333rem 0.01388889rem 0.01388889rem;
  color: white;
}
@media (max-width: 1023px) {
  .PhotosPopup-image-info-top {
    padding: 0.01458333rem 0.01388889rem 0.01388889rem;
  }
}
.PhotosPopup-image-info-title {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.00833333rem;
  line-height: 0.01388889rem;
  font-weight: 500;
}
@media (max-width: 1023px) {
  .PhotosPopup-image-info-title {
    font-size: 0.00833333rem;
    line-height: 0.01388889rem;
  }
}
@media (max-width: 1023px) {
  .PhotosPopup-image-info-title {
    font-size: 0.00694444rem;
    line-height: 0.01388889rem;
    letter-spacing: 0rem;
  }
}
.PhotosPopup-image-info-place {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-size: 0.01666667rem;
  line-height: 0.01388889rem;
  letter-spacing: -0.00086806rem;
  margin-top: -0.00069444rem;
}
.PhotosPopup-image-info-numbers {
  background: #F7F5F2;
  color: #795fc5;
  padding: 0.00763889rem 0.01388889rem;
}
.PhotosPopup-image-info-number {
  position: relative;
  border-bottom: 0.00138889rem solid #795fc5;
  padding: 0.00694444rem 0 0.00555556rem;
}
.PhotosPopup-image-info-number:last-child {
  border-bottom: none;
}
.PhotosPopup-image-info-label {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.00833333rem;
  line-height: 0.01388889rem;
  font-weight: 500;
  width: 0.125rem;
}
@media (max-width: 1023px) {
  .PhotosPopup-image-info-label {
    font-size: 0.00833333rem;
    line-height: 0.01388889rem;
  }
}
@media (max-width: 1023px) {
  .PhotosPopup-image-info-label {
    font-size: 0.00694444rem;
    line-height: 0.01388889rem;
    letter-spacing: 0rem;
  }
}
.PhotosPopup-image-info-value {
  position: absolute;
  right: 0;
  top: 0.00625rem;
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-size: 0.01666667rem;
  line-height: 0.01388889rem;
  letter-spacing: -0.00069444rem;
}
.PhotosPopup-arrow-prev,
.PhotosPopup-arrow-next {
  display: block;
  z-index: 2;
  position: absolute;
  width: 0.03888889rem;
  height: 0.02291667rem;
  overflow: hidden;
  bottom: 0.01597222rem;
}
@media (max-width: 1023px) {
  .PhotosPopup-arrow-prev,
  .PhotosPopup-arrow-next {
    display: none;
  }
}
.isDesktop .PhotosPopup-arrow-prev:hover svg,
.isDesktop .PhotosPopup-arrow-next:hover svg,
.isMobile .PhotosPopup-arrow-prev:active svg,
.isMobile .PhotosPopup-arrow-next:active svg {
  left: 0;
}
.PhotosPopup-arrow-prev svg,
.PhotosPopup-arrow-next svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  fill: #fff;
  left: -0.00902778rem;
}
.isDesktop .PhotosPopup-arrow-prev svg,
.isDesktop .PhotosPopup-arrow-next svg {
  transition: 0.15s left ease-out, 0.15s right ease-out;
}
.PhotosPopup-arrow-prev {
  right: 0.06458333rem;
  transform: scaleX(-1);
}
.PhotosPopup-arrow-next {
  right: 0.01180556rem;
}
