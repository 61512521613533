@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}.IndexAuthors {
  position: relative;
  color: #795fc5;
  border-bottom: 0.00138889rem solid #795fc5;
  padding-bottom: 0.07916667rem;
  margin-top: -0.13888889rem;
}
@media (max-width: 1023px) {
  .IndexAuthors {
    margin-top: 0;
    padding-bottom: 0.03888889rem;
  }
}
.IndexAuthors:before {
  content: '';
  display: block;
  position: relative;
  width: 100%;
  height: 0.00138889rem;
  background: #795fc5;
}
@media (min-width: 1024px) {
  .IndexAuthors:before {
    transition: 0.35s ease-out opacity;
    will-change: opacity;
    opacity: 0;
  }
}
.IndexAuthors.show-border:before {
  opacity: 1;
}
.IndexAuthors-wrapper {
  position: relative;
  padding: 0 0.04166667rem;
}
@media (max-width: 1023px) {
  .IndexAuthors-wrapper {
    padding: 0 0.01388889rem;
  }
}
.IndexAuthors h2 {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.08333333rem;
  line-height: 0.08333333rem;
  letter-spacing: -0.00607639rem;
  text-transform: uppercase;
  margin-top: 0.0375rem;
}
@media (max-width: 1023px) {
  .IndexAuthors h2 {
    font-size: 0.04166667rem;
    line-height: 0.04166667rem;
    letter-spacing: -0.00304167rem;
  }
}
@media (max-width: 1023px) {
  .IndexAuthors h2 {
    margin-top: 0.01736111rem;
  }
}
.IndexAuthors-columns {
  margin-top: 0.03680556rem;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1023px) {
  .IndexAuthors-columns {
    margin-top: 0.02083333rem;
    flex-wrap: wrap;
  }
}
.IndexAuthors-columns.show .IndexAuthors-column {
  opacity: 1;
}
.IndexAuthors-column {
  flex: 0 0 0.19444444rem;
  opacity: 0;
  will-change: opacity;
}
@media (min-width: 1024px) {
  .IndexAuthors-column:nth-child(1) {
    transition: 0.35s ease-out 0.1s;
  }
  .IndexAuthors-column:nth-child(2) {
    transition: 0.35s ease-out 0.275s;
  }
}
@media (max-width: 1023px) {
  .IndexAuthors-column {
    margin-bottom: 0.04513889rem;
  }
  .IndexAuthors-column:last-child {
    margin-bottom: 0;
  }
  .IndexAuthors-column:nth-child(1) {
    transition: 0.35s ease-out 0.1s;
  }
  .IndexAuthors-column:nth-child(2) {
    transition: 0.35s ease-out 0.1s;
  }
}
.IndexAuthors-column h3 {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.02222222rem;
  line-height: 0.02777778rem;
  letter-spacing: -0.00115741rem;
}
@media (max-width: 1023px) {
  .IndexAuthors-column h3 {
    font-size: 0.01666667rem;
    line-height: 0.02777778rem;
    letter-spacing: -0.00086806rem;
  }
}
@media (max-width: 1023px) {
  .IndexAuthors-column h3 {
    line-height: 0.02083333rem;
  }
  .IndexAuthors-column h3 br {
    display: none;
  }
}
.IndexAuthors-column p {
  font-family: 'Kazimir', serif;
  font-weight: 400;
  font-size: 0.00833333rem;
  line-height: 0.01388889rem;
  margin-top: 0.01319444rem;
}
@media (max-width: 1023px) {
  .IndexAuthors-column p {
    font-size: 0.00833333rem;
    line-height: 0.01388889rem;
  }
}
@media (max-width: 1023px) {
  .IndexAuthors-column p {
    margin-top: 0.01458333rem;
  }
}
.IndexAuthors-column img {
  position: relative;
  display: block;
  width: 0.13888889rem;
  height: 0.13888889rem;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 0.02291667rem;
}
.IndexAuthors-column ul {
  margin-top: 0.03194444rem;
}
@media (max-width: 1023px) {
  .IndexAuthors-column ul {
    margin-top: 0.03194444rem;
  }
}
.IndexAuthors-column ul li {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.00833333rem;
  line-height: 0.01388889rem;
  position: relative;
  margin: 0;
  padding: 0 0 0 0.01666667rem;
  list-style-type: none;
}
@media (max-width: 1023px) {
  .IndexAuthors-column ul li {
    font-size: 0.00833333rem;
    line-height: 0.01388889rem;
  }
}
@media (max-width: 1023px) {
  .IndexAuthors-column ul li {
    padding: 0 0 0 0.01666667rem;
  }
}
.IndexAuthors-column ul li:before {
  content: "";
  position: absolute;
  display: block;
  top: 0.00625rem;
  left: 0;
  width: 0.00347222rem;
  height: 0.00347222rem;
  border-radius: 50%;
  background: #795fc5;
}
@media (max-width: 1023px) {
  .IndexAuthors-column ul li:before {
    top: 0.00555556rem;
    width: 0.00347222rem;
    height: 0.00347222rem;
  }
}
.IndexAuthors-column a {
  position: relative;
  display: inline-block;
  color: #795fc5;
  text-decoration: none;
  cursor: pointer;
}
.IndexAuthors-column a:before {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00069444rem solid #795fc5;
  top: 85%;
  width: 100%;
  transform: scaleX(1);
  transform-origin: 100% 0;
  transition: 0s;
}
.IndexAuthors-column a:after {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00069444rem solid #795fc5;
  top: 85%;
  width: 100%;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0s;
}
.isDesktop .IndexAuthors-column a:hover:before,
.isMobile .IndexAuthors-column a:active:before {
  transform: scaleX(0);
  transition: transform 0.195s ease-out;
}
.isDesktop .IndexAuthors-column a:hover:after,
.isMobile .IndexAuthors-column a:active:after {
  transform: scaleX(1);
  transition: transform 0.195s ease-in 0.195s;
}
