@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}.TinkoffPayForm {
  display: none;
}
.OrderPopup {
  position: relative;
  color: white;
}
@media (max-width: 1023px) {
  .OrderPopup {
    overflow: hidden;
  }
}
.OrderPopup-wrapper {
  padding: 0.04583333rem 0 0;
}
@media (max-width: 1023px) {
  .OrderPopup-wrapper {
    padding: 0.01527778rem 0 0;
  }
}
.OrderPopup-error {
  display: none;
  position: absolute;
  top: 50%;
  margin-top: -0.00972222rem;
  right: 100%;
  margin-right: 0.02777778rem;
  width: 0.11111111rem;
  color: #795fc5;
  height: 0.02777778rem;
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 500;
  font-size: 0.00833333rem;
  line-height: 0.02777778rem;
  padding-left: 0.00902778rem;
  background: white;
}
@media (max-width: 1023px) {
  .OrderPopup-error {
    top: 0;
    margin-top: -0.00555556rem;
    right: auto;
    left: 0;
    margin-right: 0;
    width: 0.08680556rem;
    height: 0.01388889rem;
    font-size: 0.00694444rem;
    line-height: 0.01319444rem;
    padding-left: 0.00381944rem;
    white-space: nowrap;
  }
}
.OrderPopup-error:after {
  content: "";
  position: absolute;
  left: 100%;
  top: 0;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.01388889rem 0 0.01388889rem 0.01388889rem;
  border-color: transparent transparent transparent #fff;
}
@media (max-width: 1023px) {
  .OrderPopup-error:after {
    border-width: 0.00694444rem 0 0.00694444rem 0.00694444rem;
  }
}
.OrderPopup-header {
  position: relative;
  width: 0.8625rem;
  margin: 0 auto;
  margin-bottom: 0.07916667rem;
}
@media (max-width: 1023px) {
  .OrderPopup-header {
    width: 0.19444444rem;
    margin-bottom: 0.07986111rem;
  }
}
.OrderPopup-header h1 span {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.08333333rem;
  line-height: 0.08333333rem;
  letter-spacing: -0.00607639rem;
  text-transform: uppercase;
  display: block;
  line-height: 0.06944444rem;
}
@media (max-width: 1023px) {
  .OrderPopup-header h1 span {
    font-size: 0.04166667rem;
    line-height: 0.04166667rem;
    letter-spacing: -0.00304167rem;
  }
}
.OrderPopup-header h1 span:last-child {
  margin-left: -0.02569444rem;
}
@media (max-width: 1023px) {
  .OrderPopup-header h1 span:last-child {
    margin-left: 0;
    text-indent: -0.01180556rem;
  }
}
@media (max-width: 1023px) {
  .OrderPopup-header h1 span {
    font-size: 0.03333333rem;
    line-height: 0.02777778rem;
    letter-spacing: -0.00243056rem;
  }
}
.OrderPopup-header div p:first-child {
  margin-top: 0.02916667rem;
}
.OrderPopup-header div p {
  font-family: 'Kazimir', serif;
  font-weight: 400;
  font-size: 0.01111111rem;
  line-height: 0.01388889rem;
  margin-top: 0.01944444rem;
  max-width: 0.41666667rem;
}
@media (max-width: 1023px) {
  .OrderPopup-header div p {
    font-size: 0.01111111rem;
    line-height: 0.01388889rem;
  }
}
@media (max-width: 1023px) {
  .OrderPopup-header div p {
    margin-top: 0.01736111rem;
    font-size: 0.00833333rem;
    line-height: 0.01388889rem;
    letter-spacing: 0;
    font-weight: 400;
  }
}
.OrderPopup-header div a {
  position: relative;
  display: inline-block;
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.OrderPopup-header div a:before {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00069444rem solid white;
  top: 85%;
  width: 100%;
  transform: scaleX(1);
  transform-origin: 100% 0;
  transition: 0s;
}
.OrderPopup-header div a:after {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00069444rem solid white;
  top: 85%;
  width: 100%;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0s;
}
.isDesktop .OrderPopup-header div a:hover:before,
.isMobile .OrderPopup-header div a:active:before {
  transform: scaleX(0);
  transition: transform 0.195s ease-out;
}
.isDesktop .OrderPopup-header div a:hover:after,
.isMobile .OrderPopup-header div a:active:after {
  transform: scaleX(1);
  transition: transform 0.195s ease-in 0.195s;
}
.OrderPopup-header img {
  position: absolute;
  right: 0;
  top: 0.01041667rem;
  width: 0.08888889rem;
  height: 0.125rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
@media (max-width: 1023px) {
  .OrderPopup-header img {
    display: none;
  }
}
.OrderPopup-price,
.OrderPopup-delivery,
.OrderPopup-address,
.OrderPopup-help,
.OrderPopup-agreed,
.OrderPopup-footer {
  width: 0.86111111rem;
  margin: 0 auto;
  border-top: 0.00138889rem solid #fff;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1023px) {
  .OrderPopup-price,
  .OrderPopup-delivery,
  .OrderPopup-address,
  .OrderPopup-help,
  .OrderPopup-agreed,
  .OrderPopup-footer {
    width: 0.19444444rem;
    border-top: none;
    display: block;
  }
}
.OrderPopup-price {
  padding: 0.01666667rem 0 0.08888889rem;
}
@media (max-width: 1023px) {
  .OrderPopup-price {
    padding: 0;
  }
}
.OrderPopup-delivery {
  padding: 0.01666667rem 0 0.04305556rem;
}
@media (max-width: 1023px) {
  .OrderPopup-delivery {
    padding: 0;
    margin-top: 0.09861111rem;
  }
}
.OrderPopup-delivery .OrderPopup-right {
  padding-top: 0.00972222rem;
}
@media (max-width: 1023px) {
  .OrderPopup-delivery .OrderPopup-right {
    padding-top: 0.00902778rem;
  }
}
.OrderPopup-address {
  padding: 0.01666667rem 0 0.07777778rem;
}
@media (max-width: 1023px) {
  .OrderPopup-address {
    padding: 0;
    margin-top: 0.05833333rem;
  }
}
.OrderPopup-address .OrderPopup-right {
  padding-top: 0.00138889rem;
}
@media (max-width: 1023px) {
  .OrderPopup-address .OrderPopup-right {
    padding-top: 0.01458333rem;
  }
}
.OrderPopup-help {
  padding: 0.01666667rem 0 0.06875rem;
}
@media (max-width: 1023px) {
  .OrderPopup-help {
    padding: 0;
    margin-top: 0.07083333rem;
  }
}
.OrderPopup-help .OrderPopup-right {
  padding-top: 0.00972222rem;
}
@media (max-width: 1023px) {
  .OrderPopup-help .OrderPopup-right {
    padding-top: 0.00902778rem;
  }
}
.OrderPopup-agreed {
  padding: 0.02708333rem 0 0.02708333rem;
  justify-content: center;
}
@media (max-width: 1023px) {
  .OrderPopup-agreed {
    padding: 0;
    margin-top: 0.04236111rem;
  }
}
.OrderPopup-footer {
  padding: 0.02361111rem 0 0.0375rem;
}
@media (max-width: 1023px) {
  .OrderPopup-footer {
    padding: 0;
    margin-top: 0.02569444rem;
  }
}
.OrderPopup-footer .OrderPopup-right {
  padding-top: 0.00694444rem;
}
@media (max-width: 1023px) {
  .OrderPopup-footer .OrderPopup-right {
    padding: 0;
    margin-top: 0.03819444rem;
  }
}
.OrderPopup-left {
  flex: 1 0;
}
.OrderPopup-right {
  flex: 0 0 0.41666667rem;
}
.OrderPopup-title {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.04444444rem;
  line-height: 0.04166667rem;
  letter-spacing: -0.0023125rem;
  text-transform: uppercase;
  white-space: nowrap;
}
@media (max-width: 1023px) {
  .OrderPopup-title {
    font-size: 0.03333333rem;
    line-height: 0.02777778rem;
    letter-spacing: -0.00243056rem;
  }
}
.OrderPopup-note {
  font-family: 'Kazimir', serif;
  font-weight: 400;
  font-size: 0.00833333rem;
  line-height: 0.01388889rem;
  margin-top: 0.01458333rem;
  width: 0.19444444rem;
}
@media (max-width: 1023px) {
  .OrderPopup-note {
    font-size: 0.00833333rem;
    line-height: 0.01388889rem;
  }
}
@media (max-width: 1023px) {
  .OrderPopup-note {
    margin-top: 0.01736111rem;
  }
}
.OrderPopup-input {
  width: 100%;
  position: relative;
  margin-bottom: 0.00416667rem;
  border-bottom: 0.00138889rem solid white;
}
@media (max-width: 1023px) {
  .OrderPopup-input {
    margin-bottom: 0.00486111rem;
    border-bottom: 0.00138889rem solid white;
  }
}
.OrderPopup-input.big {
  padding-bottom: 0.00138889rem;
  margin-top: -0.00694444rem;
}
@media (max-width: 1023px) {
  .OrderPopup-input.big {
    padding-bottom: 0.00555556rem;
    margin-top: 0.01597222rem;
  }
}
.OrderPopup-input.big input {
  text-align: left;
  height: 0.05555556rem;
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.04444444rem;
  line-height: 0.05555556rem;
  letter-spacing: -0.00324074rem;
}
@media (max-width: 1023px) {
  .OrderPopup-input.big input {
    height: 0.04166667rem;
    font-size: 0.03333333rem;
    line-height: 0.04166667rem;
    letter-spacing: -0.00243056rem;
  }
}
.OrderPopup-input.small {
  padding-bottom: 0.00625rem;
}
@media (max-width: 1023px) {
  .OrderPopup-input.small {
    padding: 0.00763889rem 0 0.01180556rem;
  }
}
.OrderPopup-input.small input,
.OrderPopup-input.small textarea {
  position: relative;
  text-align: right;
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.02222222rem;
  line-height: 0.02916667rem;
  letter-spacing: -0.00115741rem;
  width: calc(100% - 0.06944444rem);
  margin-left: 0.06944444rem;
  max-height: 0.14652778rem;
}
@media (max-width: 1023px) {
  .OrderPopup-input.small input,
  .OrderPopup-input.small textarea {
    font-size: 0.01111111rem;
    line-height: 0.01527778rem;
    letter-spacing: -0.00057639rem;
    width: calc(100% - 0.0625rem);
    margin-left: 0.0625rem;
    max-height: 0.07708333rem;
  }
}
.OrderPopup-input.small input {
  height: 0.02916667rem;
}
@media (max-width: 1023px) {
  .OrderPopup-input.small input {
    height: 0.01527778rem;
  }
}
@media (min-width: 1024px) {
  .OrderPopup-input.short-start {
    display: inline-block;
    width: 0.19444444rem;
    vertical-align: top;
    margin-right: 0.02777778rem;
  }
}
@media (min-width: 1024px) {
  .OrderPopup-input.short-end {
    display: inline-block;
    width: 0.19444444rem;
    vertical-align: top;
  }
}
.OrderPopup-input input,
.OrderPopup-input textarea {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  color: #fff;
  margin: 0;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  background: transparent;
  border: 0;
  box-shadow: none;
  outline: 0;
  text-align: left;
  padding: 0;
  resize: none;
  -webkit-transition: color 9999s ease-out, background-color 9999s ease-out, font-size 9999s ease-out;
  -webkit-transition-delay: 9999s;
}
.OrderPopup-input input[disabled],
.OrderPopup-input textarea[disabled] {
  color: rgba(255, 255, 255, 0.7);
}
.OrderPopup-input input::-webkit-input-placeholder,
.OrderPopup-input textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.25);
}
.OrderPopup-input input::-moz-placeholder,
.OrderPopup-input textarea::-moz-placeholder {
  color: rgba(255, 255, 255, 0.25);
}
.OrderPopup-input input:-moz-placeholder,
.OrderPopup-input textarea:-moz-placeholder {
  color: rgba(255, 255, 255, 0.25);
}
.OrderPopup-input label {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 500;
  font-size: 0.00833333rem;
  line-height: 0.01388889rem;
  position: absolute;
  left: 0;
  top: 0.0125rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
@media (max-width: 1023px) {
  .OrderPopup-input label {
    font-weight: 400;
    font-size: 0.00694444rem;
    line-height: 0.01388889rem;
    top: 0.00972222rem;
  }
}
.OrderPopup-price-input-currency {
  position: absolute;
  top: 0;
  right: 0;
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.04444444rem;
  line-height: 0.05555556rem;
  letter-spacing: -0.00324074rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
@media (max-width: 1023px) {
  .OrderPopup-price-input-currency {
    font-size: 0.03333333rem;
    line-height: 0.04166667rem;
    letter-spacing: -0.00243056rem;
  }
}
.OrderPopup-price-presets {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-top: 0.01388889rem;
}
@media (max-width: 1023px) {
  .OrderPopup-price-presets {
    width: 0.20138889rem;
    margin-left: -0.00486111rem;
    justify-content: start;
    flex-wrap: wrap;
  }
}
.OrderPopup-price-preset {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 500;
  font-size: 0.00833333rem;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding: 0 0.02361111rem 0 0.02361111rem;
  height: 0.02777778rem;
  line-height: 0.02638889rem;
  text-decoration: none;
  box-sizing: content-box;
  vertical-align: middle;
  border-radius: 0.01388889rem;
  box-sizing: border-box;
  padding: 0;
  color: white;
  border: 0.00069444rem solid white;
  background: rgba(255, 255, 255, 0);
  width: 0.06944444rem;
  line-height: 0.02777778rem;
  flex: 0 0 0.06944444rem;
}
.OrderPopup-price-preset[disabled] {
  pointer-events: none;
}
.isDesktop .OrderPopup-price-preset {
  transition: 0.15s ease color, 0.15s ease border, 0.15s ease background;
}
.OrderPopup-price-preset.loading {
  pointer-events: none;
  color: transparent;
}
.OrderPopup-price-preset.loading span {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -0.00694444rem;
  margin-top: -0.00694444rem;
}
.isDesktop .OrderPopup-price-preset:hover,
.isMobile .OrderPopup-price-preset:active {
  color: #795fc5;
  background: #ffffff;
}
@media (max-width: 1023px) {
  .OrderPopup-price-preset {
    flex: 0 0 0.05555556rem;
    margin: 0.00555556rem 0.00416667rem;
  }
}
.OrderPopup-price-gradations {
  position: relative;
  margin-top: 0.02222222rem;
}
@media (max-width: 1023px) {
  .OrderPopup-price-gradations {
    margin-top: 0.02152778rem;
  }
}
.OrderPopup-price-gradation {
  font-family: 'Kazimir', serif;
  font-weight: 400;
  font-size: 0.02222222rem;
  line-height: 0.02777778rem;
  letter-spacing: -0.00115972rem;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
}
@media (max-width: 1023px) {
  .OrderPopup-price-gradation {
    font-size: 0.01111111rem;
    line-height: 0.01388889rem;
    letter-spacing: -0.00057639rem;
  }
}
.OrderPopup-price-gradation.active {
  opacity: 1;
  pointer-events: all;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
}
@media (max-width: 1023px) {
  .OrderPopup-price-gradation {
    line-height: 0.01388889rem;
    letter-spacing: 0;
    font-weight: 400;
    white-space: normal;
    width: 0.20833333rem;
  }
}
.OrderPopup-delivery-row {
  padding: 0.00555556rem 0 0.00694444rem;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1023px) {
  .OrderPopup-delivery-row {
    padding: 0.01388889rem 0 0.0125rem;
  }
}
.OrderPopup-delivery-row:first-child {
  border-bottom: 0.00138889rem solid white;
}
.OrderPopup-delivery-row:last-child {
  border-top: 0.00138889rem solid white;
}
.OrderPopup-delivery-row-title {
  flex: 1 0;
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.02222222rem;
  line-height: 0.02777778rem;
  letter-spacing: -0.00115741rem;
}
@media (max-width: 1023px) {
  .OrderPopup-delivery-row-title {
    font-size: 0.01111111rem;
    line-height: 0.01388889rem;
    letter-spacing: -0.00057639rem;
  }
}
.OrderPopup-delivery-row-value {
  text-align: right;
  flex: 0 0 0.13888889rem;
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.02222222rem;
  line-height: 0.02777778rem;
  letter-spacing: -0.00115741rem;
}
@media (max-width: 1023px) {
  .OrderPopup-delivery-row-value {
    flex: 0 0 0.11111111rem;
    font-size: 0.01111111rem;
    line-height: 0.01388889rem;
    letter-spacing: -0.00057639rem;
  }
}
.OrderPopup-delivery-row-inputs {
  flex: 0 0 0.11111111rem;
  line-height: 0.04166667rem;
  margin: -0.00694444rem 0 -0.00694444rem;
}
@media (max-width: 1023px) {
  .OrderPopup-delivery-row-inputs {
    flex: 0 0 0.06944444rem;
    line-height: 0.02777778rem;
    margin: -0.00694444rem 0 -0.00694444rem;
  }
}
.OrderPopup-delivery-row-values {
  flex: 0 0 0.11111111rem;
  margin: -0.00694444rem 0 -0.00694444rem;
}
@media (max-width: 1023px) {
  .OrderPopup-delivery-row-values {
    flex: 0 0 0.04166667rem;
    margin: -0.00694444rem 0 -0.00694444rem;
  }
}
.OrderPopup-delivery-row-values .OrderPopup-delivery-row-value {
  line-height: 0.04166667rem;
}
@media (max-width: 1023px) {
  .OrderPopup-delivery-row-values .OrderPopup-delivery-row-value {
    line-height: 0.02777778rem;
  }
}
.OrderPopup-delivery-radio input {
  display: none;
}
input:checked + label .OrderPopup-delivery-radio-icon:after {
  background: radial-gradient(ellipse at center, #fff 0%, #fff 32%, transparent 37%, transparent 100%);
}
.OrderPopup-delivery-radio label {
  position: relative;
  cursor: pointer;
  display: inline-block;
}
.OrderPopup-delivery-radio-icon {
  width: 0.01388889rem;
  height: 0.01388889rem;
  display: inline-block;
  vertical-align: -0.00833333rem;
  position: relative;
}
@media (max-width: 1023px) {
  .OrderPopup-delivery-radio-icon {
    vertical-align: -0.00694444rem;
  }
}
.OrderPopup-delivery-radio-icon:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 1px solid white;
  border-radius: 50%;
}
.OrderPopup-delivery-radio-icon:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.OrderPopup-delivery-radio-text {
  padding-left: 0.01388889rem;
  vertical-align: -0.00833333rem;
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.02222222rem;
  line-height: 0.02777778rem;
  letter-spacing: -0.00115741rem;
}
@media (max-width: 1023px) {
  .OrderPopup-delivery-radio-text {
    padding-left: 0.00694444rem;
    vertical-align: -0.00416667rem;
    font-size: 0.01111111rem;
    line-height: 0.01388889rem;
    letter-spacing: -0.00057639rem;
  }
}
.OrderPopup-help-row {
  padding: 0.00555556rem 0 0.00694444rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.00138889rem solid white;
}
@media (max-width: 1023px) {
  .OrderPopup-help-row {
    padding: 0.01388889rem 0 0.0125rem;
  }
}
.OrderPopup-help-row-title {
  flex: 1 0;
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.02222222rem;
  line-height: 0.02777778rem;
  letter-spacing: -0.00115741rem;
}
@media (max-width: 1023px) {
  .OrderPopup-help-row-title {
    font-size: 0.01111111rem;
    line-height: 0.01388889rem;
    letter-spacing: -0.00057639rem;
  }
}
.OrderPopup-help-row-percentage {
  flex: 0 0 0.0375rem;
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.02222222rem;
  line-height: 0.02777778rem;
  letter-spacing: -0.00115741rem;
}
@media (max-width: 1023px) {
  .OrderPopup-help-row-percentage {
    flex: 0 0 0.02777778rem;
    font-size: 0.01111111rem;
    line-height: 0.01388889rem;
    letter-spacing: -0.00057639rem;
  }
}
.OrderPopup-help-row-value {
  text-align: right;
  flex: 0 0 0.11111111rem;
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.02222222rem;
  line-height: 0.02777778rem;
  letter-spacing: -0.00115741rem;
}
@media (max-width: 1023px) {
  .OrderPopup-help-row-value {
    flex: 0 0 0.05555556rem;
    font-size: 0.01111111rem;
    line-height: 0.01388889rem;
    letter-spacing: -0.00057639rem;
  }
}
.OrderPopup-agreed-checkbox input {
  display: none;
}
.OrderPopup-agreed-checkbox input:checked + label:after {
  opacity: 1 !important;
}
.isDesktop .OrderPopup-agreed-checkbox:hover label:after {
  opacity: 0.3;
}
.OrderPopup-agreed-checkbox.error {
  -webkit-animation: shake 0.5s linear;
  animation: shake 0.5s linear;
}
@-webkit-keyframes shake {
  8%,
  41% {
    -webkit-transform: translateX(-0.00694444rem);
  }
  25%,
  58% {
    -webkit-transform: translateX(0.00694444rem);
  }
  75% {
    -webkit-transform: translateX(-0.00347222rem);
  }
  92% {
    -webkit-transform: translateX(0.00347222rem);
  }
  0%,
  100% {
    -webkit-transform: translateX(0);
  }
}
@keyframes shake {
  8%,
  41% {
    transform: translateX(-0.00694444rem);
  }
  25%,
  58% {
    transform: translateX(0.00694444rem);
  }
  75% {
    transform: translateX(-0.00347222rem);
  }
  92% {
    transform: translateX(0.00347222rem);
  }
  0%,
  100% {
    transform: translateX(0);
  }
}
.OrderPopup-agreed-checkbox label {
  position: relative;
  cursor: pointer;
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 500;
  font-size: 0.00833333rem;
  line-height: 0.01388889rem;
  padding-left: 0.03125rem;
  display: inline-block;
  vertical-align: middle;
}
@media (max-width: 1023px) {
  .OrderPopup-agreed-checkbox label {
    width: 0.20416667rem;
    padding-left: 0.04166667rem;
  }
}
.OrderPopup-agreed-checkbox label a {
  color: white;
  text-decoration: underline;
}
.isDesktop .OrderPopup-agreed-checkbox label a:hover,
.isMobile .OrderPopup-agreed-checkbox label a:active {
  text-decoration: none;
}
.OrderPopup-agreed-checkbox label:before {
  position: absolute;
  content: "";
  display: block;
  left: 0;
  top: -0.00416667rem;
  width: 0.02083333rem;
  height: 0.02083333rem;
  border: 1px solid #fff;
}
@media (max-width: 1023px) {
  .OrderPopup-agreed-checkbox label:before {
    top: 0.01388889rem;
    width: 0.02777778rem;
    height: 0.02777778rem;
  }
}
.OrderPopup-agreed-checkbox label:after {
  position: absolute;
  left: 0rem;
  top: -0.00416667rem;
  content: "";
  display: block;
  width: 0.02083333rem;
  height: 0.02083333rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 0;
  pointer-events: all;
  background-image: url("/static/img/checkbox-mark.svg");
}
.isDesktop .OrderPopup-agreed-checkbox label:after {
  transition: 0.05s opacity linear;
}
@media (max-width: 1023px) {
  .OrderPopup-agreed-checkbox label:after {
    top: 0.01388889rem;
    width: 0.02777778rem;
    height: 0.02777778rem;
  }
}
.OrderPopup-footer-logos-desktop {
  margin-top: 0.01666667rem;
  width: 0.31666667rem;
}
@media (max-width: 1023px) {
  .OrderPopup-footer-logos-desktop {
    display: none;
  }
}
.OrderPopup-footer-logos-mobile {
  width: 0.19444444rem;
}
@media (min-width: 1024px) {
  .OrderPopup-footer-logos-mobile {
    display: none;
  }
}
.OrderPopup-footer-pay {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
  width: 100%;
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.04444444rem;
  line-height: 0.04166667rem;
  letter-spacing: -0.0023125rem;
  text-transform: uppercase;
  padding-right: 0.06875rem;
}
@media (max-width: 1023px) {
  .OrderPopup-footer-pay {
    color: #795fc5;
    width: 0.22222222rem;
    margin-left: -0.01388889rem;
    font-size: 0.01666667rem;
    line-height: 0.07083333rem;
    letter-spacing: -0.00086806rem;
    padding-right: 0.04166667rem;
    background: white;
    height: 0.06944444rem;
    padding-left: 0.01388889rem;
  }
}
.isDesktop .OrderPopup-footer-pay:hover svg,
.isMobile .OrderPopup-footer-pay:active svg {
  left: 0;
}
.OrderPopup-footer-pay em {
  position: absolute;
  display: inline-block;
  top: 0.01180556rem;
  right: -0.01111111rem;
  width: 0.03888889rem;
  height: 0.02291667rem;
  overflow: hidden;
}
@media (max-width: 1023px) {
  .OrderPopup-footer-pay em {
    top: 0.02361111rem;
    right: 0.00277778rem;
  }
}
.OrderPopup-footer-pay svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  left: -0.00902778rem;
  fill: #fff;
}
.isDesktop .OrderPopup-footer-pay svg {
  transition: 0.15s left ease-out;
}
@media (max-width: 1023px) {
  .OrderPopup-footer-pay svg {
    fill: #795fc5;
  }
}
