@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}.BasePopup {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 11000;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.35s linear, visibility 0.35s step-end;
}
.BasePopup-panel {
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 9000;
}
@media (max-width: 1023px) {
  .BasePopup-panel {
    top: 0 !important;
  }
}
.BasePopup-close {
  display: block;
  position: fixed;
  width: 0.04027778rem;
  height: 0.04027778rem;
  top: 0.00763889rem;
  right: 0.00763889rem;
  cursor: pointer;
  z-index: 100;
}
@media (max-width: 1023px) {
  .BasePopup-close {
    width: 0.02361111rem;
    height: 0.02361111rem;
    top: 0.00208333rem;
    right: 0.00208333rem;
  }
}
.isDesktop .BasePopup-close:hover svg,
.isMobile .BasePopup-close:active svg {
  opacity: 0.7;
}
.BasePopup-close svg {
  position: absolute;
  width: 100%;
  height: 100%;
  fill: #fff;
  top: 0;
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.5));
}
.isDesktop .BasePopup-close svg {
  transition: 0.15s all opacity;
}
.BasePopup-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #795fc5;
}
.BasePopup-content {
  position: relative;
  width: 100%;
  min-height: 100vh;
}
.BasePopup.BasePopup--visible {
  visibility: inherit;
  opacity: 1;
  transition: opacity 0.35s linear, visibility 0.35s step-start;
}
