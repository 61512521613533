@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}.IndexInside {
  position: relative;
  color: #795fc5;
  border-bottom: 0.00138889rem solid #795fc5;
  overflow: hidden;
}
.IndexInside-wrapper {
  position: relative;
  padding-top: 0.0375rem;
  padding: 0.0375rem 0.04166667rem 0;
}
@media (max-width: 1023px) {
  .IndexInside-wrapper {
    padding: 0.01736111rem 0.01388889rem 0;
  }
}
.IndexInside h2 {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.08333333rem;
  line-height: 0.08333333rem;
  letter-spacing: -0.00607639rem;
  text-transform: uppercase;
  position: relative;
  margin-left: -0.00555556rem;
}
@media (max-width: 1023px) {
  .IndexInside h2 {
    font-size: 0.04166667rem;
    line-height: 0.04166667rem;
    letter-spacing: -0.00304167rem;
  }
}
@media (max-width: 1023px) {
  .IndexInside h2 {
    margin-left: 0;
  }
}
.IndexInside-text {
  font-family: 'Kazimir', serif;
  font-weight: 400;
  font-size: 0.01111111rem;
  line-height: 0.01388889rem;
  margin-top: 0.04930556rem;
  opacity: 0;
  transition: 0.35s ease-out 0.1s;
  will-change: opacity;
}
@media (max-width: 1023px) {
  .IndexInside-text {
    font-size: 0.01111111rem;
    line-height: 0.01388889rem;
  }
}
@media (max-width: 1023px) {
  .IndexInside-text {
    margin-top: 0.02708333rem;
  }
}
.IndexInside-text.show {
  opacity: 1;
}
.IndexInside-gallery {
  position: relative;
  margin-top: 0.05138889rem;
  height: 0.31597222rem;
}
@media (max-width: 1023px) {
  .IndexInside-gallery {
    height: 0.15625rem;
    margin-top: 0.02430556rem;
  }
}
.IndexInside-features {
  margin-top: 0.00763889rem;
  padding: 0.0375rem 0.04166667rem 0.04166667rem;
}
@media (max-width: 1023px) {
  .IndexInside-features {
    margin-top: 0.02430556rem;
    padding: 0 0.01388889rem 0.05555556rem;
  }
}
.IndexInside-feature {
  position: relative;
  border-top: 0.00138889rem solid #795fc5;
  margin-bottom: 0.02777778rem;
  padding-top: 0.00416667rem;
  opacity: 0;
  transition: 0.35s ease-out 0.1s;
  display: flex;
  justify-content: space-between;
  will-change: opacity;
}
@media (max-width: 1023px) {
  .IndexInside-feature {
    padding-top: 0.00625rem;
    display: block;
  }
}
.IndexInside-feature.show {
  opacity: 1;
}
.IndexInside-feature.show img {
  transform: none !important;
}
.IndexInside-feature.show .IndexInside-feature-images[data-index="3"] picture:nth-child(2) img {
  transform: skew(0, -9deg) scale(0.6, 1) translateX(-0.00069444rem) !important;
}
.IndexInside-feature.show .IndexInside-feature-images[data-index="3"] picture:nth-child(4) img {
  transform: skew(0, -9deg) scale(0.6, 1) translateX(-0.00069444rem) !important;
}
.IndexInside-feature.show .IndexInside-feature-images[data-index="4"] picture:nth-child(1) img {
  transform: skew(-9deg, 0) scale(1, 0.6) translateY(0.00069444rem) !important;
}
.IndexInside-feature.show .IndexInside-feature-images[data-index="4"] picture:nth-child(3) img {
  transform: skew(-9deg, 0) scale(1, 0.6) translateY(0.00069444rem) !important;
}
.IndexInside-feature-info {
  flex: 0 0 0.19444444rem;
}
.IndexInside-feature-title {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.02222222rem;
  line-height: 0.02777778rem;
  letter-spacing: -0.00115741rem;
}
@media (max-width: 1023px) {
  .IndexInside-feature-title {
    font-size: 0.01666667rem;
    line-height: 0.02777778rem;
    letter-spacing: -0.00086806rem;
  }
}
.IndexInside-feature-text {
  font-family: 'Kazimir', serif;
  font-weight: 400;
  font-size: 0.00833333rem;
  line-height: 0.01388889rem;
  margin-top: 0.01319444rem;
}
@media (max-width: 1023px) {
  .IndexInside-feature-text {
    font-size: 0.00833333rem;
    line-height: 0.01388889rem;
  }
}
@media (max-width: 1023px) {
  .IndexInside-feature-text {
    margin-top: 0.01111111rem;
  }
}
.IndexInside-feature-images {
  position: relative;
  flex: 0 0 0.19444444rem;
  margin-top: 0.00694444rem;
}
.IndexInside-feature-images[data-index="1"] {
  height: 0.12965495rem;
}
.IndexInside-feature-images[data-index="1"] picture:nth-child(1) img {
  width: 0.04422719rem;
  left: 0.07549659rem;
  top: 0.04112266rem;
  z-index: 11;
  transform: scale(0.4);
  transition: 0.35s ease-out 0.1s;
}
.IndexInside-feature-images[data-index="1"] picture:nth-child(2) img {
  width: 0.04748603rem;
  left: 0.05493482rem;
  top: 0rem;
  z-index: 10;
  transform: translate(0.01854438rem, 0.03957087rem) scale(0.5);
  transition: 0.35s ease-out 0.145s;
}
.IndexInside-feature-images[data-index="1"] picture:nth-child(3) img {
  width: 0.04376164rem;
  left: 0.09869646rem;
  top: 0.00558648rem;
  z-index: 9;
  transform: translate(-0.02335506rem, 0.03615691rem) scale(0.5);
  transition: 0.35s ease-out 0.19s;
}
.IndexInside-feature-images[data-index="1"] picture:nth-child(4) img {
  width: 0.0386406rem;
  left: 0.1461049rem;
  top: 0.00225011rem;
  z-index: 8;
  transform: translate(-0.06820298rem, 0.03615691rem) scale(0.5);
  transition: 0.35s ease-out 0.235s;
}
.IndexInside-feature-images[data-index="1"] picture:nth-child(5) img {
  width: 0.04554624rem;
  left: 0.11956859rem;
  top: 0.0493472rem;
  z-index: 7;
  transform: translate(-0.04523588rem, -0.00729345rem) scale(0.5);
  transition: 0.35s ease-out 0.28s;
}
.IndexInside-feature-images[data-index="1"] picture:nth-child(6) img {
  width: 0.03701117rem;
  left: 0.15743327rem;
  top: 0.05850281rem;
  z-index: 6;
  transform: translate(-0.07875543rem, -0.01706979rem) scale(0.5);
  transition: 0.35s ease-out 0.325s;
}
.IndexInside-feature-images[data-index="1"] picture:nth-child(7) img {
  width: 0.04608939rem;
  left: 0.10482619rem;
  top: 0.0848058rem;
  z-index: 5;
  transform: translate(-0.03064867rem, -0.04088989rem) scale(0.5);
  transition: 0.35s ease-out 0.37s;
}
.IndexInside-feature-images[data-index="1"] picture:nth-child(8) img {
  width: 0.04554624rem;
  left: 0.05338299rem;
  top: 0.083254rem;
  z-index: 4;
  transform: translate(0.02102731rem, -0.04166579rem) scale(0.5);
  transition: 0.35s ease-out 0.415s;
}
.IndexInside-feature-images[data-index="1"] picture:nth-child(9) img {
  width: 0.05283985rem;
  left: 0rem;
  top: 0.0886077rem;
  z-index: 3;
  transform: translate(0.0707635rem, -0.03460511rem) scale(0.5);
  transition: 0.35s ease-out 0.46s;
}
.IndexInside-feature-images[data-index="1"] picture:nth-child(10) img {
  width: 0.06005587rem;
  left: 0.00876785rem;
  top: 0.05214043rem;
  z-index: 2;
  transform: translate(0.05842644rem, -0.00349155rem) scale(0.5);
  transition: 0.35s ease-out 0.505s;
}
.IndexInside-feature-images[data-index="1"] picture:nth-child(11) img {
  width: 0.05625388rem;
  left: 0.00450031rem;
  top: 0.00605201rem;
  z-index: 1;
  transform: translate(0.06455618rem, 0.03879497rem) scale(0.5);
  transition: 0.35s ease-out 0.55s;
}
.IndexInside-feature-images[data-index="2"] {
  height: 0.14861111rem;
}
.IndexInside-feature-images[data-index="2"] picture img {
  width: 0.11111111rem;
  box-shadow: 0px 0.00069444rem 0.00138889rem rgba(0, 0, 0, 0.25);
}
.IndexInside-feature-images[data-index="2"] picture:nth-child(1) img {
  right: 0;
  top: 0;
  z-index: 4;
}
.IndexInside-feature-images[data-index="2"] picture:nth-child(2) img {
  right: 0.02777778rem;
  top: 0.01388889rem;
  z-index: 3;
  transform: translate(0.02777778rem, -0.01388889rem);
  transition: 0.35s ease-out 0.1s;
}
.IndexInside-feature-images[data-index="2"] picture:nth-child(3) img {
  left: 0.02777778rem;
  bottom: 0.02777778rem;
  z-index: 2;
  transform: translate(0.05555556rem, -0.04166667rem);
  transition: 0.525s ease-out 0.1s;
}
.IndexInside-feature-images[data-index="2"] picture:nth-child(4) img {
  left: 0;
  bottom: 0;
  z-index: 1;
  transform: translate(0.08333333rem, -0.06944444rem);
  transition: 0.7s ease-out 0.1s;
}
.IndexInside-feature-images[data-index="3"] {
  height: 0.22140842rem;
}
.IndexInside-feature-images[data-index="3"] picture img {
  width: 0.13888889rem;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.25));
}
.IndexInside-feature-images[data-index="3"] picture:nth-child(1) img {
  top: 0;
  left: 0;
  z-index: 4;
}
.IndexInside-feature-images[data-index="3"] picture:nth-child(2) img {
  top: 0;
  height: 0.19444444rem;
  left: 0;
  z-index: 3;
  transition: 0.525s cubic-bezier(0.43, 1.84, 0.51, 0.98) 0.1s;
  transform-origin: 100% 0%;
}
.IndexInside-feature-images[data-index="3"] picture:nth-child(3) img {
  bottom: 0.01388889rem;
  right: 0;
  z-index: 2;
  transform: translate(-0.05555556rem, -0.01388889rem);
  transition: 0.525s cubic-bezier(0.43, 1.84, 0.51, 0.98) 0.1s;
}
.IndexInside-feature-images[data-index="3"] picture:nth-child(4) img {
  bottom: 0.01388889rem;
  height: 0.19444444rem;
  right: 0;
  z-index: 1;
  transform: translate(-0.05555556rem, -0.01388889rem);
  transition: 0.525s cubic-bezier(0.43, 1.84, 0.51, 0.98) 0.1s;
  transform-origin: 100% 0%;
}
.IndexInside-feature-images[data-index="4"] {
  height: 0.17036675rem;
}
.IndexInside-feature-images[data-index="4"] picture img {
  width: 0.17013889rem;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.25));
}
.IndexInside-feature-images[data-index="4"] picture:nth-child(1) img {
  top: 0;
  height: 0.12152778rem;
  left: 0.01215278rem;
  z-index: 1;
  transform: translate(-0.01215278rem, 0);
  transition: 0.525s cubic-bezier(0.43, 1.84, 0.51, 0.98) 0.1s;
  transform-origin: 100% 0%;
}
.IndexInside-feature-images[data-index="4"] picture:nth-child(2) img {
  top: 0;
  left: 0.01215278rem;
  z-index: 2;
  transform: translate(-0.01215278rem, 0);
  transition: 0.525s cubic-bezier(0.43, 1.84, 0.51, 0.98) 0.1s;
}
.IndexInside-feature-images[data-index="4"] picture:nth-child(3) img {
  bottom: 0;
  height: 0.12152778rem;
  right: 0;
  z-index: 3;
  transform: translate(-0.03125rem, -0.04861111rem);
  transition: 0.525s cubic-bezier(0.43, 1.84, 0.51, 0.98) 0.1s;
  transform-origin: 100% 0%;
}
.IndexInside-feature-images[data-index="4"] picture:nth-child(4) img {
  bottom: 0;
  right: 0;
  z-index: 4;
  transform: translate(-0.03125rem, -0.04861111rem);
  transition: 0.525s cubic-bezier(0.43, 1.84, 0.51, 0.98) 0.1s;
}
.IndexInside-feature-images img {
  position: absolute;
}
@media (max-width: 1023px) {
  .IndexInside-feature-images {
    width: 100%;
    margin-top: 0.00763889rem;
  }
}
