@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}@media (min-width: 1024px) {
  .GalleryPopup-wrapper {
    position: absolute;
    top: 0.06805556rem;
    bottom: 0.01388889rem;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .GalleryPopup-wrapper .CommonGallery {
    flex: 1 0;
  }
}
@media (max-width: 1023px) {
  .GalleryPopup .CommonGallery {
    display: none;
  }
}
@media (min-width: 1024px) {
  .GalleryPopup .CommonGalleryMobile {
    display: none;
  }
}
