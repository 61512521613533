@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}.CommonGallery {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
}
.isDesktop .CommonGallery.small .CommonGallery-wrapper .CommonGallery-item:hover {
  opacity: 0.85;
}
.CommonGallery.big {
  min-height: 25vw;
}
.CommonGallery.big .CommonGallery-dots {
  display: none;
}
.CommonGallery.big .CommonGallery-wrapper .CommonGallery-item[data-pos="0"] {
  cursor: default;
}
.CommonGallery.small .CommonGallery-wrapper {
  bottom: calc(2px + 0.02222222rem);
}
@media (max-width: 1023px) {
  .CommonGallery.small .CommonGallery-wrapper {
    bottom: calc(2px + 0.01875rem);
  }
}
.CommonGallery-wrapper {
  position: absolute;
  top: 0px;
  bottom: 2px;
  left: 0;
  width: 100%;
}
.CommonGallery-wrapper.has-animation .CommonGallery-item {
  transition: transform 300ms cubic-bezier(0.4, 0.24, 0.4, 1);
}
.isDesktop .CommonGallery-wrapper.has-animation .CommonGallery-item {
  transition: 0.15s ease-out opacity, transform 300ms cubic-bezier(0.4, 0.24, 0.4, 1);
}
.CommonGallery-item {
  position: absolute;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  cursor: pointer;
  will-change: transform;
}
.CommonGallery-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  pointer-events: none;
}
.CommonGallery-dots {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 0.00694444rem;
  display: flex;
  justify-content: center;
}
.CommonGallery-dots div {
  flex: 0 0 0.00694444rem;
  margin: 0 0.00347222rem;
  width: 0.00694444rem;
  height: 0.00694444rem;
  border-radius: 50%;
  background: rgba(121, 95, 197, 0);
  border: 1px solid #cac8c5;
  outline: none;
  cursor: pointer;
}
.isDesktop .CommonGallery-dots div {
  transition: 0.15s ease-out background, 0.15s ease-out border;
}
.isDesktop .CommonGallery-dots div:hover,
.isMobile .CommonGallery-dots div:active {
  background: #795fc5;
  border: 1px solid rgba(202, 200, 197, 0);
}
.CommonGallery-dots div.active {
  background: #795fc5;
  border: 1px solid rgba(202, 200, 197, 0);
}
