@font-face {
  font-family: 'AeroportRegular';
  font-display: swap;
  src: url('/static/fonts/Aeroport-regular.woff2') format('woff2'), url('/static/fonts/Aeroport-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'AeroportMedium';
  font-display: swap;
  src: url('/static/fonts/Aeroport-medium.woff2') format('woff2'), url('/static/fonts/Aeroport-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'KazimirRegular';
  font-display: swap;
  src: url('/static/fonts/Kazimir-Regular-Web.woff2') format('woff2'), url('/static/fonts/Kazimir-Regular-Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Aeroport';
  font-display: swap;
  src: url('/static/fonts/Aeroport-regular.woff2') format('woff2'), url('/static/fonts/Aeroport-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Aeroport';
  font-display: swap;
  src: url('/static/fonts/Aeroport-medium.woff2') format('woff2'), url('/static/fonts/Aeroport-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Kazimir';
  font-display: swap;
  src: url('/static/fonts/Kazimir-Regular-Web.woff2') format('woff2'), url('/static/fonts/Kazimir-Regular-Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
.FixedOnPopup {
  position: fixed !important;
  left: 0 !important;
  width: 100% !important;
}
.PopupLoader {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  transition: all 0.25s ease-in-out;
  opacity: 0;
  visibility: hidden;
  z-index: 11500;
  background: rgba(255, 255, 255, 0.5);
}
.PopupLoader.PopupLoader--visible {
  transition: opacity 0.25s ease-in-out;
  opacity: 1;
  visibility: inherit;
}
.PopupLoader.PopupLoader--visible:after {
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.PopupLoader:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -0.01111111rem;
  margin-top: -0.01111111rem;
  -webkit-animation: loading-animation-spin 700ms infinite linear;
          animation: loading-animation-spin 700ms infinite linear;
  width: 0.02222222rem;
  height: 0.02222222rem;
  border-radius: 50%;
  border: 0.00277778rem solid #795fc5;
  border-right-color: transparent;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
@media (max-width: 1023px) {
}
@-webkit-keyframes loading-animation-spin {
  to {
    transform: rotate(1turn);
  }
}
@keyframes loading-animation-spin {
  to {
    transform: rotate(1turn);
  }
}
.c-button-base {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 500;
  font-size: 0.00833333rem;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding: 0 0.02361111rem 0 0.02361111rem;
  height: 0.02777778rem;
  line-height: 0.02638889rem;
  text-decoration: none;
  box-sizing: content-box;
  vertical-align: middle;
  border-radius: 0.01388889rem;
  box-sizing: border-box;
}
.c-button-base[disabled] {
  pointer-events: none;
}
.isDesktop .c-button-base {
  transition: 0.15s ease color, 0.15s ease border, 0.15s ease background;
}
.c-button-base.loading {
  pointer-events: none;
  color: transparent;
}
.c-button-base.loading span {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -0.00694444rem;
  margin-top: -0.00694444rem;
}
.c-button-filled {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 500;
  font-size: 0.00833333rem;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding: 0 0.02361111rem 0 0.02361111rem;
  height: 0.02777778rem;
  line-height: 0.02638889rem;
  text-decoration: none;
  box-sizing: content-box;
  vertical-align: middle;
  border-radius: 0.01388889rem;
  box-sizing: border-box;
  color: #F7F5F2;
  border: 0.00138889rem solid rgba(121, 95, 197, 0);
  background: #795fc5;
}
.c-button-filled[disabled] {
  pointer-events: none;
}
.isDesktop .c-button-filled {
  transition: 0.15s ease color, 0.15s ease border, 0.15s ease background;
}
.c-button-filled.loading {
  pointer-events: none;
  color: transparent;
}
.c-button-filled.loading span {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -0.00694444rem;
  margin-top: -0.00694444rem;
}
.isDesktop .c-button-filled:hover,
.isMobile .c-button-filled:active,
.c-button-filled.active {
  color: #795fc5;
  border: 0.00138889rem solid #795fc5;
  background: #f7f5f2;
}
.c-button-filled.loading span {
  -webkit-animation: loading-animation-spin 700ms infinite linear;
          animation: loading-animation-spin 700ms infinite linear;
  width: 0.01388889rem;
  height: 0.01388889rem;
  border-radius: 50%;
  border: 0.00173611rem solid #000;
  border-right-color: transparent;
}
@media (max-width: 1023px) {
}
@keyframes loading-animation-spin {
  to {
    transform: rotate(1turn);
  }
}
.c-button-outlined {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 500;
  font-size: 0.00833333rem;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding: 0 0.02361111rem 0 0.02361111rem;
  height: 0.02777778rem;
  line-height: 0.02638889rem;
  text-decoration: none;
  box-sizing: content-box;
  vertical-align: middle;
  border-radius: 0.01388889rem;
  box-sizing: border-box;
  color: #795fc5;
  border: 0.00138889rem solid #795fc5;
  background: rgba(121, 95, 197, 0);
}
.c-button-outlined[disabled] {
  pointer-events: none;
}
.isDesktop .c-button-outlined {
  transition: 0.15s ease color, 0.15s ease border, 0.15s ease background;
}
.c-button-outlined.loading {
  pointer-events: none;
  color: transparent;
}
.c-button-outlined.loading span {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -0.00694444rem;
  margin-top: -0.00694444rem;
}
.isDesktop .c-button-outlined:hover,
.isMobile .c-button-outlined:active,
.c-button-outlined.active {
  color: #F7F5F2;
  border: 0.00138889rem solid rgba(121, 95, 197, 0);
  background: #795fc5;
}
.c-button-outlined.loading span {
  -webkit-animation: loading-animation-spin 700ms infinite linear;
          animation: loading-animation-spin 700ms infinite linear;
  width: 0.01388889rem;
  height: 0.01388889rem;
  border-radius: 50%;
  border: 0.00173611rem solid #000;
  border-right-color: transparent;
}
@media (max-width: 1023px) {
}
@keyframes loading-animation-spin {
  to {
    transform: rotate(1turn);
  }
}
.c-button-price-preset {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 500;
  font-size: 0.00833333rem;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding: 0 0.02361111rem 0 0.02361111rem;
  height: 0.02777778rem;
  line-height: 0.02638889rem;
  text-decoration: none;
  box-sizing: content-box;
  vertical-align: middle;
  border-radius: 0.01388889rem;
  box-sizing: border-box;
  padding: 0;
  color: white;
  border: 0.00069444rem solid white;
  background: rgba(255, 255, 255, 0);
  width: 0.06944444rem;
  line-height: 0.02777778rem;
}
.c-button-price-preset[disabled] {
  pointer-events: none;
}
.isDesktop .c-button-price-preset {
  transition: 0.15s ease color, 0.15s ease border, 0.15s ease background;
}
.c-button-price-preset.loading {
  pointer-events: none;
  color: transparent;
}
.c-button-price-preset.loading span {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -0.00694444rem;
  margin-top: -0.00694444rem;
}
.isDesktop .c-button-price-preset:hover,
.isMobile .c-button-price-preset:active {
  color: #795fc5;
  background: #ffffff;
}
*,
*:after,
*:before {
  box-sizing: border-box;
}
html {
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: transparent;
  overflow-y: scroll;
  font-size: 1000px;
}
::-moz-selection {
  background: #795fc5;
  /* WebKit/Blink Browsers */
  color: #fff;
}
::selection {
  background: #795fc5;
  /* WebKit/Blink Browsers */
  color: #fff;
}
::-moz-selection {
  background: #795fc5;
  /* Gecko Browsers */
  color: #fff;
}
html.no-transition * {
  transition: none !important;
}
body {
  font-family: 'Kazimir', serif;
  opacity: 1 !important;
  position: relative;
  font-size: 0;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip: ink;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -ms-font-feature-settings: "kern" 1;
  font-feature-settings: "kern", normal;
  font-variant-numeric: normal;
  word-wrap: break-word;
  background: #F7F5F2;
}
input,
button,
textarea {
  font-family: 'Aeroport', Helvetica, sans-serif;
}
input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}
::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}
input::-ms-clear {
  display: none !important;
}
input::-ms-reveal {
  display: none !important;
}
input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: transparent !important;
}
input:focus::-moz-placeholder, textarea:focus::-moz-placeholder {
  color: transparent !important;
}
input:focus::-ms-input-placeholder, textarea:focus::-ms-input-placeholder {
  color: transparent !important;
}
input:focus::placeholder,
textarea:focus::placeholder {
  color: transparent !important;
}
button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  text-align: inherit;
  outline: none;
  border-radius: 0;
  cursor: pointer;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
}
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
svg {
  overflow: visible;
}
p:empty:after {
  content: "\00a0";
}
