@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}.IndexProcess {
  position: relative;
  color: #795fc5;
  border-bottom: 0.00138889rem solid #795fc5;
  overflow: hidden;
}
.IndexProcess-wrapper {
  position: relative;
  padding-top: 0.0375rem;
  padding: 0.0375rem 0.04166667rem 0.04166667rem;
}
@media (max-width: 1023px) {
  .IndexProcess-wrapper {
    padding: 0.01736111rem 0.01388889rem 0.01111111rem;
  }
}
.IndexProcess h2 {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.08333333rem;
  line-height: 0.08333333rem;
  letter-spacing: -0.00607639rem;
  text-transform: uppercase;
  position: relative;
  margin-left: -0.00555556rem;
}
@media (max-width: 1023px) {
  .IndexProcess h2 {
    font-size: 0.04166667rem;
    line-height: 0.04166667rem;
    letter-spacing: -0.00304167rem;
  }
}
@media (max-width: 1023px) {
  .IndexProcess h2 {
    margin-left: 0;
  }
}
.IndexProcess-stages {
  margin-top: 0.04513889rem;
}
@media (max-width: 1023px) {
  .IndexProcess-stages {
    margin-top: 0.03680556rem;
  }
}
.IndexProcess-stage {
  position: relative;
  border-top: 0.00138889rem solid #795fc5;
  margin-bottom: 0.02291667rem;
  padding-top: 0.00416667rem;
  opacity: 0;
  transition: 0.35s ease-out 0.1s;
  display: flex;
  justify-content: space-between;
  will-change: opacity;
}
@media (max-width: 1023px) {
  .IndexProcess-stage {
    display: block;
    padding-top: 0.01041667rem;
  }
}
.IndexProcess-stage.show {
  opacity: 1;
}
.IndexProcess-stage-title {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.02222222rem;
  line-height: 0.02777778rem;
  letter-spacing: -0.00115741rem;
  flex: 0 0 0.20138889rem;
}
@media (max-width: 1023px) {
  .IndexProcess-stage-title {
    font-size: 0.01666667rem;
    line-height: 0.02777778rem;
    letter-spacing: -0.00086806rem;
  }
}
@media (max-width: 1023px) {
  .IndexProcess-stage-title {
    line-height: 0.02083333rem;
  }
}
.IndexProcess-stage-period {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 500;
  font-size: 0.00833333rem;
  line-height: 0.01388889rem;
  margin-top: 0.00902778rem;
  letter-spacing: 0;
}
@media (max-width: 1023px) {
  .IndexProcess-stage-period {
    font-weight: 500;
    font-size: 0.00694444rem;
    line-height: 0.01388889rem;
    margin-top: 0.00972222rem;
    letter-spacing: 0;
  }
}
.IndexProcess-stage-text {
  font-family: 'Kazimir', serif;
  font-weight: 400;
  font-size: 0.00833333rem;
  line-height: 0.01388889rem;
  flex: 0 0 0.19444444rem;
  margin-top: 0.01319444rem;
}
@media (max-width: 1023px) {
  .IndexProcess-stage-text {
    font-size: 0.00833333rem;
    line-height: 0.01388889rem;
  }
}
@media (max-width: 1023px) {
  .IndexProcess-stage-text {
    margin-top: 0.01875rem;
  }
}
.IndexProcess-stage-text a {
  position: relative;
  display: inline-block;
  color: #795fc5;
  text-decoration: none;
  cursor: pointer;
}
.IndexProcess-stage-text a:before {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00069444rem solid #795fc5;
  top: 80%;
  width: 100%;
  transform: scaleX(1);
  transform-origin: 100% 0;
  transition: 0s;
}
.IndexProcess-stage-text a:after {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00069444rem solid #795fc5;
  top: 80%;
  width: 100%;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0s;
}
.isDesktop .IndexProcess-stage-text a:hover:before,
.isMobile .IndexProcess-stage-text a:active:before {
  transform: scaleX(0);
  transition: transform 0.195s ease-out;
}
.isDesktop .IndexProcess-stage-text a:hover:after,
.isMobile .IndexProcess-stage-text a:active:after {
  transform: scaleX(1);
  transition: transform 0.195s ease-in 0.195s;
}
.IndexProcess-stage-text .anchor {
  position: relative;
  display: inline-block;
  color: #795fc5;
  text-decoration: none;
  cursor: pointer;
}
.IndexProcess-stage-text .anchor:before {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00069444rem solid #795fc5;
  top: 80%;
  width: 100%;
  transform: scaleX(1);
  transform-origin: 100% 0;
  transition: 0s;
}
.IndexProcess-stage-text .anchor:after {
  content: '';
  display: block;
  position: absolute;
  height: 0px;
  border-bottom: 0.00069444rem solid #795fc5;
  top: 80%;
  width: 100%;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0s;
}
.isDesktop .IndexProcess-stage-text .anchor:hover:before,
.isMobile .IndexProcess-stage-text .anchor:active:before {
  transform: scaleX(0);
  transition: transform 0.195s ease-out;
}
.isDesktop .IndexProcess-stage-text .anchor:hover:after,
.isMobile .IndexProcess-stage-text .anchor:active:after {
  transform: scaleX(1);
  transition: transform 0.195s ease-in 0.195s;
}
.IndexProcess-stage-text .anchor:before {
  border-bottom: 0.00069444rem dotted #795fc5;
}
.IndexProcess-stage-text .anchor:after {
  border-bottom: 0.00069444rem dotted #795fc5;
}
.IndexProcess-collage {
  position: relative;
  display: block;
  margin: 0.04166667rem auto 0.02777778rem;
  width: 0.41666667rem;
  height: 0.33472222rem;
  opacity: 0;
  transition: 0.35s ease-out 0.1s;
  will-change: opacity;
}
@media (max-width: 1023px) {
  .IndexProcess-collage {
    margin: 0.02777778rem auto 0.02777778rem;
    width: 0.19444444rem;
    height: 0.15625rem;
  }
}
.IndexProcess-collage:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  box-shadow: inset 0 0 0.02083333rem #f7f5f2;
}
.IndexProcess-collage.show {
  opacity: 1;
}
.IndexProcess-collage img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
