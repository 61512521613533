@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}.IndexCover {
  background: #795fc5;
  min-height: 20vw;
  height: 100vh;
  width: 50%;
  color: #fff;
  z-index: 1;
}
@media (min-width: 1024px) {
  .IndexCover {
    position: fixed;
    left: 0;
    top: 0;
    width: 50%;
  }
}
@media (max-width: 1023px) {
  .IndexCover {
    position: relative;
    min-height: 0.23611111rem;
    height: calc(100vh - 0.10694444rem);
    width: 100%;
    background: #F7F5F2;
    overflow: hidden;
  }
  .IndexCover:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    transform: scaleY(0);
    transform-origin: 50% 0;
    background: #795fc5;
    transition: 0.35s transform;
  }
  .IndexCover.show h1 {
    opacity: 1;
  }
  .IndexCover.show:before {
    transform: none;
  }
}
.IndexCover-wrapper {
  position: relative;
  padding: 0.01111111rem 0.025rem 0.01736111rem;
  height: 100%;
}
@media (max-width: 1023px) {
  .IndexCover-wrapper {
    padding: 0.00486111rem 0.00555556rem 0.00902778rem;
  }
}
.IndexCover-photos {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0.01388889rem;
  right: 0.01388889rem;
  overflow: hidden;
  opacity: 0;
  transition: 0.35s ease-out;
  will-change: opacity;
}
@media (max-width: 1023px) {
  .IndexCover-photos {
    display: none;
  }
}
.IndexCover-photos.show {
  opacity: 1;
}
.IndexCover-photos-photo {
  position: absolute;
  will-change: transform;
  cursor: pointer;
  z-index: 1;
}
.IndexCover-photos-photo.small {
  width: 0.0877193rem;
  height: 0.05847953rem;
}
.IndexCover-photos-photo.medium {
  width: 0.11695906rem;
  height: 0.07821637rem;
}
.IndexCover-photos-photo img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.95);
  will-change: transform;
}
.isDesktop .IndexCover-photos-photo img {
  transition: 0.15s ease-out transform;
}
.isDesktop .IndexCover-photos-photo img:hover,
.isMobile .IndexCover-photos-photo img:active {
  z-index: 3;
  transform: scale(1);
}
.IndexCover h1 {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.08333333rem;
  line-height: 0.08333333rem;
  letter-spacing: -0.00607639rem;
  text-transform: uppercase;
  letter-spacing: -0.00434028rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  pointer-events: none;
}
@media (max-width: 1023px) {
  .IndexCover h1 {
    font-size: 0.04166667rem;
    line-height: 0.04166667rem;
    letter-spacing: -0.00304167rem;
  }
}
@media (max-width: 1023px) {
  .IndexCover h1 {
    letter-spacing: -0.00304167rem;
    opacity: 0;
    transition: 0s opacity 0.35s;
  }
}
.IndexCover h1 > span {
  display: inline-block;
  will-change: transform;
  z-index: 2;
  white-space: nowrap;
}
.IndexCover h1 > span:nth-child(2) {
  margin-left: 0.00277778rem;
}
@media (max-width: 1023px) {
  .IndexCover h1 > span:nth-child(2) {
    margin-left: 0.00138889rem;
  }
}
.IndexCover h1 > span:nth-child(2) span:nth-last-child(2) {
  letter-spacing: 0.00017361rem;
}
@media (max-width: 1023px) {
  .IndexCover h1 > span {
    transform: none !important;
  }
}
.IndexCover-note {
  position: absolute;
  right: 0.04166667rem;
  bottom: 0.025rem;
  text-align: right;
  width: 0.11805556rem;
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 500;
  font-size: 0.01111111rem;
  line-height: 0.01388889rem;
  letter-spacing: -0.00013889rem;
  text-transform: uppercase;
  z-index: 2;
  pointer-events: none;
  opacity: 0;
  transition: 0.35s ease-out 0.7s;
  will-change: opacity;
}
@media (max-width: 1023px) {
  .IndexCover-note {
    right: 0.00694444rem;
    bottom: 0.01319444rem;
    width: 0.06666667rem;
    font-size: 0.00694444rem;
    line-height: 0.00729167rem;
    letter-spacing: -0.00009028rem;
  }
}
.IndexCover-note.show {
  opacity: 1;
}
.IndexCover-note.shown {
  transition: 0.35s ease-out opacity;
}
.IndexCover-image {
  position: absolute;
  left: 0.10416667rem;
  right: 0.10416667rem;
  top: 25vh;
  bottom: 27.27272727vh;
  z-index: 1;
  opacity: 0;
  transition: 0.35s ease-out 0.7s;
  will-change: opacity;
  pointer-events: none;
}
@media (max-width: 1023px) {
  .IndexCover-image {
    transform: none !important;
    left: 0.02777778rem;
    right: 0.02777778rem;
    top: 0.08333333rem;
    bottom: 0.09305556rem;
  }
}
.IndexCover-image.show {
  opacity: 1;
}
.IndexCover-image.shown {
  transition: 0.35s ease-out opacity;
}
.IndexCover-image img {
  position: absolute;
  transform: scale(0.905);
  pointer-events: all;
  filter: drop-shadow(0.00138889rem 0rem 0.01388889rem rgba(0, 0, 0, 0));
}
@media (max-width: 1023px) {
  .IndexCover-image img {
    transform: scale(0.905);
  }
}
@media (max-width: 1023px) {
  .IndexCover-image img {
    pointer-events: none;
  }
}
.isDesktop .IndexCover-image img {
  transition: 0.15s ease-out;
}
.isDesktop .IndexCover-image img:hover,
.isMobile .IndexCover-image img:active {
  transform: scale(1) translateY(-0.00486111rem);
  filter: drop-shadow(0.00138889rem 0.02777778rem 0.02083333rem rgba(0, 0, 0, 0.25));
}
@media (max-width: 1023px) {
  .isDesktop .IndexCover-image img:hover,
  .isMobile .IndexCover-image img:active {
    transform: scale(1) translateY(-0.00208333rem);
    filter: drop-shadow(0.00069444rem 0.01388889rem 0.01041667rem rgba(0, 0, 0, 0.25));
  }
}
.isDesktop .IndexCover-image:hover + .IndexCover-image-buy p,
.isMobile .IndexCover-image:active + .IndexCover-image-buy p {
  opacity: 1;
  visibility: inherit;
  transition: 0.4s opacity, 0.4s visibility step-start;
}
.IndexCover-image-buy {
  position: absolute;
  left: 0;
  width: 100%;
  height: 0.08333333rem;
  top: 50%;
  margin-top: -0.04166667rem;
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.08333333rem;
  line-height: 0.08333333rem;
  letter-spacing: -0.00434028rem;
  text-transform: uppercase;
  color: #FFFFFF;
  white-space: nowrap;
  overflow: hidden;
  pointer-events: none;
  visibility: hidden;
}
.IndexCover-image-buy.shown {
  visibility: inherit;
}
@media (max-width: 1023px) {
  .IndexCover-image-buy {
    display: none;
  }
}
.IndexCover-image-buy p {
  display: inline-block;
  opacity: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  -webkit-animation: buyloop 5s linear infinite both;
          animation: buyloop 5s linear infinite both;
  transition: 0.4s opacity, 0.4s visibility step-end;
  visibility: hidden;
}
@-webkit-keyframes buyloop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
@keyframes buyloop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
