@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}@media (max-width: 1023px) {
}.IndexFeedback {
  position: relative;
  color: #795fc5;
  border-bottom: 0.00138889rem solid #795fc5;
}
.IndexFeedback-wrapper {
  position: relative;
  padding: 0.0375rem 0.04166667rem 0.09722222rem;
}
@media (max-width: 1023px) {
  .IndexFeedback-wrapper {
    padding: 0.01736111rem 0.01388889rem 0.05833333rem;
  }
}
.IndexFeedback h2 {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.08333333rem;
  line-height: 0.08333333rem;
  letter-spacing: -0.00607639rem;
  text-transform: uppercase;
  position: relative;
  margin-left: -0.00833333rem;
}
@media (max-width: 1023px) {
  .IndexFeedback h2 {
    font-size: 0.04166667rem;
    line-height: 0.04166667rem;
    letter-spacing: -0.00304167rem;
  }
}
@media (max-width: 1023px) {
  .IndexFeedback h2 {
    margin-left: 0;
  }
}
.IndexFeedback-list {
  margin-top: 0.04513889rem;
}
@media (max-width: 1023px) {
  .IndexFeedback-list {
    margin-top: 0.02291667rem;
  }
}
.IndexFeedback-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 0.0125rem;
  border-top: 0.00138889rem solid #795fc5;
  opacity: 0;
  transition: 0.35s ease-out 0.1s;
  will-change: opacity;
}
@media (max-width: 1023px) {
  .IndexFeedback-buttons-wrapper {
    padding-top: 0.0125rem;
    flex-direction: column;
    align-items: center;
  }
}
.IndexFeedback-buttons-wrapper.show {
  opacity: 1;
}
.IndexFeedback-button-send {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 500;
  font-size: 0.00833333rem;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding: 0 0.02361111rem 0 0.02361111rem;
  height: 0.02777778rem;
  line-height: 0.02638889rem;
  text-decoration: none;
  box-sizing: content-box;
  vertical-align: middle;
  border-radius: 0.01388889rem;
  box-sizing: border-box;
  color: #F7F5F2;
  border: 0.00138889rem solid rgba(121, 95, 197, 0);
  background: #795fc5;
  flex: 0 0;
  white-space: nowrap;
  min-width: 0.13888889rem;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}
.IndexFeedback-button-send[disabled] {
  pointer-events: none;
}
.isDesktop .IndexFeedback-button-send {
  transition: 0.15s ease color, 0.15s ease border, 0.15s ease background;
}
.IndexFeedback-button-send.loading {
  pointer-events: none;
  color: transparent;
}
.IndexFeedback-button-send.loading span {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -0.00694444rem;
  margin-top: -0.00694444rem;
}
.isDesktop .IndexFeedback-button-send:hover,
.isMobile .IndexFeedback-button-send:active,
.IndexFeedback-button-send.active {
  color: #795fc5;
  border: 0.00138889rem solid #795fc5;
  background: #f7f5f2;
}
.IndexFeedback-button-send.loading span {
  -webkit-animation: loading-animation-spin 700ms infinite linear;
          animation: loading-animation-spin 700ms infinite linear;
  width: 0.01388889rem;
  height: 0.01388889rem;
  border-radius: 50%;
  border: 0.00173611rem solid #000;
  border-right-color: transparent;
}
@media (max-width: 1023px) {
}
@-webkit-keyframes loading-animation-spin {
  to {
    transform: rotate(1turn);
  }
}
@keyframes loading-animation-spin {
  to {
    transform: rotate(1turn);
  }
}
@media (max-width: 1023px) {
  .IndexFeedback-button-send {
    order: 2;
    margin-top: 0.01388889rem;
    height: 0.02638889rem;
    line-height: 0.025rem;
  }
}
.IndexFeedback-button-more {
  font-family: 'Aeroport', Helvetica, sans-serif;
  font-weight: 500;
  font-size: 0.00833333rem;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding: 0 0.02361111rem 0 0.02361111rem;
  height: 0.02777778rem;
  line-height: 0.02638889rem;
  text-decoration: none;
  box-sizing: content-box;
  vertical-align: middle;
  border-radius: 0.01388889rem;
  box-sizing: border-box;
  color: #795fc5;
  border: 0.00138889rem solid #795fc5;
  background: rgba(121, 95, 197, 0);
  flex: 0 0;
  white-space: nowrap;
  min-width: 0.13888889rem;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}
.IndexFeedback-button-more[disabled] {
  pointer-events: none;
}
.isDesktop .IndexFeedback-button-more {
  transition: 0.15s ease color, 0.15s ease border, 0.15s ease background;
}
.IndexFeedback-button-more.loading {
  pointer-events: none;
  color: transparent;
}
.IndexFeedback-button-more.loading span {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -0.00694444rem;
  margin-top: -0.00694444rem;
}
.isDesktop .IndexFeedback-button-more:hover,
.isMobile .IndexFeedback-button-more:active,
.IndexFeedback-button-more.active {
  color: #F7F5F2;
  border: 0.00138889rem solid rgba(121, 95, 197, 0);
  background: #795fc5;
}
.IndexFeedback-button-more.loading span {
  -webkit-animation: loading-animation-spin 700ms infinite linear;
          animation: loading-animation-spin 700ms infinite linear;
  width: 0.01388889rem;
  height: 0.01388889rem;
  border-radius: 50%;
  border: 0.00173611rem solid #000;
  border-right-color: transparent;
}
@media (max-width: 1023px) {
}
@keyframes loading-animation-spin {
  to {
    transform: rotate(1turn);
  }
}
.IndexFeedback-button-more.no-more {
  display: none;
}
@media (max-width: 1023px) {
  .IndexFeedback-button-more {
    order: 1;
    height: 0.02638889rem;
    line-height: 0.025rem;
  }
}
